var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "info position-absolute b-1  h-100  border border-dark",
      class: { "build-page": _vm.loading }
    },
    [
      _vm.model
        ? _c("div", { staticClass: "card" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "card-title" }, [
                _vm._v("Название карточки")
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-label": "Close" },
                  on: { click: _vm.hide }
                },
                [_vm._v("×")]
              )
            ]),
            _vm._v(" "),
            _vm._m(0)
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-body" }, [
      _c("ul", { staticClass: "list-group list-group-flush" }, [
        _c("li", { staticClass: "list-group-item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("Создано")]),
          _c("span", [_vm._v("653")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "list-group-item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("Обновлено")]),
          _c("span", [_vm._v("654")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "list-group-item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("Владелец")]),
          _c("span", [_vm._v("654")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "list-group-item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("Кластеров")]),
          _c("span", [_vm._v("654")])
        ])
      ]),
      _vm._v(" "),
      _c("h6", [_vm._v("Доступно:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }