"use strict";

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'


Vue.use(Vuex)

import dashboard 	from './components/seo/dashboard/store'
import tree 	from './components/seo/tree/store'

export default new Vuex.Store({
	state: {
		status: '',
		path: null,
		submenu: null,
		//error: error,
		token: localStorage.getItem('token') || '',
		user :  JSON.parse(localStorage.getItem('user')) || null
	},

	mutations: {

		auth_success(state, {token, user}){
			state.user   = user
			state.token  = token

		},

		logout(state){
			state.user  = null
			state.token = null
			state.submenu = null
		},

		submenu(state,error){
			state.submenu  = error
		},

		error(state,error){
			state.submenu  = submenu
		},
	},

	actions: {

		login({commit}, token) {
			return new Promise((resolve, reject) => {
				axios.post('/auth/register',{token: token})
				.then(response => {
					let user = response.data.user
					let token = response.data.token
					localStorage.setItem('user', JSON.stringify( user) )
					localStorage.setItem('token', token)
					axios.defaults.headers.common['Authorization'] = token
					commit('auth_success', {token, user})
					resolve(response)
				})
				.catch(error =>  {
					localStorage.removeItem('token')
					reject(error)
				} )

			})
		},

		logout({commit}){
			return new Promise((resolve, reject) => {
				commit('logout')
				localStorage.removeItem('token')
				localStorage.removeItem('user')
				delete axios.defaults.headers.common['Authorization']
				resolve()
			})
		}

	},

	getters : {
		isLoggedIn: state => !!state.token

	},

	modules: {
		dashboard : dashboard,
		tree : tree
	}

})
