<template>
	<b-modal
		title="Поделиться"
		ref="modal"
		@show="resetModal"
      	@hidden="resetModal"
      	@ok="handleOk"
		>
		<form ref="form" @submit.stop.prevent="handleSubmit">
			<b-form-group
				:state="state"
				label="Укажите email пользователя"
				label-for="email-input"
				invalid-feedback="Укажите email пользователя">

				<b-form-input
					id="email-input"
					type='email'
					v-model="email"
					:state="state"
					required
				></b-form-input>

			</b-form-group>
		</form>
	</b-modal>
</template>

<script>
export default {
	name: 'share-form',
	data() {
		return {
			email:  '',
			state: null,
			resolve: null
		}
	},
	methods: {

		validate () {
			const valid = this.$refs.form.checkValidity()
			this.state = valid ? 'valid' : 'invalid'
			return valid
		},

		resetModal() {
			this.email = ''
			this.state = null
		},

		handleSubmit() {
			if ( this.validate () ) {
				this.$nextTick( () => {
					this.$refs.modal.hide()
				})
				this.resolve(this.email)
			}
		},
		handleOk (bvModalEvt) {
			bvModalEvt.preventDefault()
			this.handleSubmit()
		},
		show  () {
			this.$refs['modal'].show()
			return  new Promise( (resolve) =>  {
					this.resolve = resolve
			})
		}

	}

}
</script>