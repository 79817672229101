var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100 " },
    [
      _c(
        "b-navbar-nav",
        [
          _c("b-button-group", [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-outline-dark",
                attrs: { title: "Сбросить" },
                on: {
                  click: function($event) {
                    return _vm.reset()
                  }
                }
              },
              [_c("v-icon", { attrs: { icon: "synchronize" } })],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "h-100",
        class: { "build-page": _vm.loading },
        attrs: { id: "tree-canvas" }
      }),
      _vm._v(" "),
      _vm.doSearch ? _c("search") : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.modal-xl",
              modifiers: { "modal-xl": true }
            }
          ],
          ref: "export-form",
          attrs: { title: "Экспорт" }
        },
        [
          _c(
            "b-form-group",
            [
              _c("b-form-textarea", {
                attrs: { rows: "15", wrap: "off" },
                model: {
                  value: _vm.exportText,
                  callback: function($$v) {
                    _vm.exportText = $$v
                  },
                  expression: "exportText"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }