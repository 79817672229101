
import Vue from 'vue';
import VueRouter from 'vue-router';
import Leads from './components/Leads.vue';
import Customers from './components/Customers.vue';
import Orders from './components/Orders.vue';
//import SeoTree from './components/SeoTree.vue';
import SeoTree from './components/seo/tree/page.vue';
import SeoTreeEdit from './components/seo/editor/page.vue';
import KanbanBoard from 'vue-kanban';
import Axios from 'axios'
//import router from './router'
import store from './store'
import icon from './icon.vue'
import BootstrapVue from 'bootstrap-vue'
import dashboard 	from './components/seo/dashboard/page.vue'


Vue.use(VueRouter);
Vue.use(KanbanBoard);
Vue.use(BootstrapVue)
Vue.component('v-icon',icon)
Vue.prototype.$http = Axios;


window.Vue = Vue;
const routes = [
    {
        path: '/crm/leads',
        component:  Leads
    },
    {
        path: '/crm/orders',
        component:  Orders
    },
    {
        path: '/crm/customers',
        component:  Customers
    },
	{
		path: '/seo/',
		component: dashboard ,
		name:'dashboard',
		meta: {
			requiresAuth: true
		}
	},	
    {
        path: '/seo/tree', 
        component:  SeoTree
    },
    {
        path: '/seo/edit', 
        component:  SeoTreeEdit
    },

]

const router = new VueRouter({ routes, mode:'history' })



const app = new Vue({
    store,
    router,
    el:'#app'
})




