"use strict";
import axios from 'axios'

export default {

	namespaced: true,

	state: {
		list : []
	},


	mutations: {
		set(state,list)  {
			state.list = list
		},

		add(state,model)  {
			state.list.push(model)
		},

		clear(state)  {
			state.list = []
		},

		remove(state,model)  {
			state.list.splice(state.list.indexOf(model), 1)
		},

	},

	actions: {
		loadtree({commit}) {
			commit('clear')
			return new Promise((resolve, reject) => {
				axios.get('/tree')
				.then((response) => {
					commit('set',response.data)
					resolve(response)
				})
				.catch(error => {
					reject(error)
				})
			})
		},

		share({commit}, [model,email]) {
			return new Promise((resolve, reject) => {
				axios.post('/tree/'+model.id + '/share', {email:email})
				.then((response) => {
					resolve(response)
				})
				.catch(error => {
					reject(error)
				})
			})
		},

		copy({commit}, model)  {
			return new Promise((resolve, reject) => {
				axios
				.post('/tree/'+model.id +'/copy')
				.then(response  => {
					commit('add', response.data)
					resolve(response)
				})
				.catch(error => {
					reject(error)
				})
			})
		},

		save({commit}, model)  {
			return new Promise((resolve, reject) => {
				axios
				.put('/tree/'+model.id, model )
				.then(response  => {
					resolve(response)
				} )
				.catch(error => {
					reject(error)
				})
			})
		},

		upload({commit}, [data,progressHandler]) {

			const config = {
				headers: { 'Content-Type': 'multipart/form-data' },
				onUploadProgress:progressHandler
			}

			return new Promise((resolve, reject) => {
				axios.post('/seo/upload', data, config)
				.then(response  =>  {
					console.log(response.data)
					commit('add', response.data)
					resolve(response)
				})
				.catch(error => {
					reject(error)
				})
			})
		},

		remove({commit}, model) {

			return new Promise((resolve, reject) => {

				if (this.state.user.id == model.owner.id) {
					var request = axios.delete('/tree/'+model.id)
				} else {
					var request = axios.post('/tree/'+model.id +'/unlink')
				}
				request.then(response  => {
					commit('remove',model)
					resolve(response)
				} )
				.catch(error => {
					reject(error)
				})
			})
		},
	}
}