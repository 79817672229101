"use strict";

const Konva  = require('konva')
const _  	= require ('lodash')
//const Node  = require ('./node.js')
import Node from './node.js'
import Tree from '../common/tree.js'
import axios from "axios";

const POS_LEFT = 1 , POS_RIGHT = 2

//module.exports = class SimpleTree  extends   Konva.Layer   {
export default class SimpleTree  extends   Konva.Layer   {


	constructor(options = {}) {
		super({draggable:false, ...options})

		// цели для перетаскивания
		this.drag_targets 	= []

		// выделенные элементы
		this.selected = []

		// перемещаемый в текущй мимент элемент
		this.drop_node = null

		// корень дерева
		this.root = null

		// резинка выделения
		this.selectRect =  new Konva.Rect({
			listening:false,
			fill:'#eeeeee66',
			stroke:'#00000066',
			strokewidth:1,
			dash : [3, 2]
		 })

		// Начало выделение объектов
		this.on('selectStart', (evt) =>  {
			this.add(this.selectRect)
			this.selectRect.position(evt.startPosition).size({width:0,height:0})
		})

		this.on('selectEnd', (evt) =>  {
			this.selectRect.remove()
			this.rubberSelected.forEach(node => this.selectNode(node) )
			this.batchDraw()
		})

		this.on('selectMove', (evt) =>  {
			this.selectRect.size({
				width: evt.currentPosition.x - evt.startPosition.x ,
				height:evt.currentPosition.y - evt.startPosition.y
			})
			this.rubberSelect(this.selectRect)
			this.batchDraw()
		})

		//  клик по пустому месту, снимае выделение
		this.on('stageClick', (evt) => {
				this.clearSelected();
		})

		// Развернуть / свернуть ноду
		this.on('nodeClick', ([node,evt] = args) =>  {
			if (this.selectedMode())  {
				this.clearSelected();
			} else {
				if (! node.isLast()) {
					(node.state.expand ?  this.collapse : this.expand).call(this, node)
				}
			}

		})

		// выбор по клику
		this.on('nodeSelect', ([node,evt] = args) =>  {
			if ( this.selected.includes(node) ) {
				this.unselectNode(node, evt.evt.shiftKey)
			} else {
				! node.isRoot() &&  this.selectNode(node, evt.evt.shiftKey)
			}
			this.batchDraw()
		})


		let k = null;
		this.on('resize', () =>
			{
				k && window.clearTimeout(k)
				k = window.setTimeout(() => {
					let scale =  Math.max(this.getStage().scaleX(),1)
					this.find('.cached').cache( {pixelRatio:scale} )
				}, 500)

			}
		)

		this.on('nodeMouseover', ([node,evt] = args) =>  {
			this.getStage().setPointer('pointer')
			node.addStyle('mouseover')
			node.allParents(true).forEach( (node) => node.compound && node.compound.setActivePath())
			this.clearBeforeDraw(false);
			this.batchDraw()
			this.clearBeforeDraw(true);

		})

		this.on('nodeMouseout', ([node,evt] = args) =>  {
			this.getStage().setPointer('default')
			node.removeStyle('mouseover')
			node.allParents(true).forEach( (node) => node.compound && node.compound.unsetActivePath())
			this.clearBeforeDraw(false);
			this.batchDraw()
			this.clearBeforeDraw(true);
		})


		// события для переназначения родителей
		this.on('nodeDragstart', ([node,evt] = args) => this.initDrag(node) )
		this.on('nodeDragend', ([node,evt] = args) => this.endDrag(node) )
		this.on('nodeDragmove', ([node,evt] = args) =>  this.dragNode( node, evt  ))


	}


	// Устанавливаем цели для дропа(куда можно прикрепить)
	initDrag(node) {

		// запомним  положение относительно родителя
		node.old_signParentPositionX =   node.signParentPositionX()

		if (  this.selectedMode()  )  {
			 // снимем выделение, если тащим не за выделенный
			 ! this.selected.includes(node) &&  this.clearSelected()

		} else {

			// переместим выше всех
			node.addStyle('drag').moveToTop()

			// получаем список узлов к которым можем прикрепить
			this.root.forAllChilden((target)=>
				{
					if (target != node.parent &&  target.inViewPort(true)) { // В фильтр нельзя, т.к. прерывается рекурсия
						target.addStyle('targets')
						this.drag_targets.push(target)
					}
				},
				true,
				(target) => node != target  && 	target.isVisible()
			)
		}

	}



	/**
	 * Перемещение ноды
	 * @param  Node  node  за что тащили
	 * @param  Event  evt
	 * @return this
	 */
	dragNode(node, evt ) {

		let movement =  {
			x:evt.evt.movementX  / this.getStage().scaleX(),
			y:evt.evt.movementY / this.getStage().scaleY()
		}

		if ( ! this.selectedMode()  )  { // Если нет выделения

			let collisions = []

			// Найдем  пересечения с другими объектами, к которым можно  прикрепить узел
			this.drag_targets.forEach( distantion => {
				if (Konva.Util.haveIntersection(node.rect(),distantion.rect()))  {
					collisions.push(distantion)
				}
			})


			if (collisions.length )  { // есть пересечения
				if ( collisions[0] != this.drop_node ) {
					this.drop_node && this.drop_node.removeStyle('target')  // сбросим предыдущий

					this.drop_node = collisions[0] // назначим нового
					this.drop_node.addStyle('target')
				}

			} else {
				this.drop_node && this.drop_node.removeStyle('target')
				this.drop_node = null
			}

			if (! evt.evt.ctrlKey) { // если не нажат Ctrl тащим за собой  потомков

				node.forAllChilden( (node)=> node.move(movement), false )

				if ( node.old_signParentPositionX != node.signParentPositionX() ) { // перебрасываем если нужно
					node.old_signParentPositionX = node.signParentPositionX()
					this.flipChilds(node, node.signParentPositionX())
				}
			}

		} else { // перемещение группы выделенных

			let targetNode = node;  // За что тащим
			this.selected
				.filter( node => node != targetNode )
				.forEach( (node) => {
					node.move(movement)
					node.forAllVisible( node =>  node.move(movement) )
				})
		}

		return this
	}

	/**
	 * Окончание перемещение ноды
	 * @param  Node  node  что тащили
	 * @param  Event  evt
	 * @return this
	 */
	endDrag(node) {

		if ( this.selected.length == 0  )  { // одиночное перемещение


			if (this.drop_node) { // есть цель, куда будем цеплять

				this.drop_node.addChild( node ) // прицепим узел

				//  перемещения всех дочерних элементов ноды, которю прицепили к новому родителю

				let old_position = node.position()  // сохраним  позиция где мы были
				this.compose(this.drop_node)   // пересчитаем положения дочерних  элементов  нового родителя
				let new_position = node.position()

				// переместим дочерние на новое место
				node.initCompound(true)
				node.forAllChilden((node) => {
					node.move({x:new_position.x - old_position.x , y:new_position.y - old_position.y })
					node.initCompound(true) // изменим цвет связей
				}, false)

				this.drop_node.removeStyle('target')

			}

			// сбрасываем все стили
			this.drag_targets.forEach((node)=> node.removeStyle( 'targets' ) )
			this.drag_targets = []
			node.removeStyle( 'drag' )
		}

		this.batchDraw()
		
		this.savePositions()
		
		return this
	}

	/**
	 * Отобразить дочерние ноды относительно родителя
	 * @param  Node  node  родительская нода
	 * @param  integer  sign   новое положение относительно центра (-1, 1)
	 * @return this
	 */
	flipChilds(node, sign) {

		let offset =  node.getXCenter()

		const moveLeft = (node)  => {
			let distance =  Math.abs(offset - node.position().x)
			return  offset - distance - node.width()

		}
		const moveRight = (node)  => {
			let distance =  Math.abs(offset - (node.position().x  + node.width())    )
			return  offset + distance
		}

		node.forAllChilden( node => {
			let x = (sign < 1) ? moveRight(node)  : moveLeft(node)
			node.x(x)
		})
		return this
	}


	/**
	 * Есть ли выделенные элементы дерева
	 * @return boolean
	 */
	selectedMode()  {
		return  this.selected.length >  0;
	}

	/**
	 * Снять все выделение
	 * @return this
	 */
	clearSelected() {
		this.selected.forEach( node =>	node.removeStyle('selected') )
		this.selected = []
		this.batchDraw()
		return this
	}


	/**
	 * Убрать выделение для ноды
	 * @param  Node node
	 * @param  boolean  mode_child  Так же  снять выделить у всех дочерние
	 * @return this
	 */
	unselectNode(node, mode_child) {

		_.pull(this.selected,node)
		node.removeStyle('selected')

		if (mode_child) {

			node.forAllChilden(
				(node)=>{
					_.pull(this.selected,node)
					node.removeStyle('selected')
				},
				false,
				(node) => node.isVisible()
			)
		}

		return this
	}

	/**
	 * Выделить ноду
	 * @param  Node node
	 * @param  boolean  mode_child  Так же  выделить все дочерние
	 * @return this
	 */

	selectNode(node,  mode_child ) {

		if ( this.selected.includes(node))  return

		this.selected.push(node)
		node.addStyle('selected')

		if (mode_child) {
			node.forAllChilden((node)=>
				{
					this.selected.push(node)
					node.addStyle('selected')
				},
				false,
				(node) => node.isVisible()
			)
		}
		return this
	}


	/**
	 * Выделение "резинкой""
	 * @param  Konva.Rect selectRegion
	 * @return this
	 */
	rubberSelect(selectRegion) {

		let x	= selectRegion.x(),
			y   = selectRegion.y(),
			width  = selectRegion.width(),
			height = selectRegion.height()

		if ( width < 0 ) {
			[x ,width ] = [x +  width , Math.abs(width)  ]
		}
		if ( height < 0 ) {
			[y ,height ] = [y + height , Math.abs(height)  ]
		}

		let selectRect =  {x: x, y: y,width: width, height: height}
		this.rubberSelected = []
		this.root.forAllChilden((node)=>
			{
				if ( Konva.Util.haveIntersection(selectRect , node.rect()) )  {
					node.addStyle('selected')
					this.rubberSelected.push(node)
				} else {
					node.removeStyle('selected')
				}
			},
			false,
			(node)  => node.isVisible() && ! this.selected.includes(node)
		)
		return this
	}




	/**
	 * Раскрыть  ветку
	 * [expand description]
	 * @return this
	 */
	expand(node, move = true, redraw = true) {
		node.addStyle('expand')
		node.state.expand = true
		if (! node.state.compose ) {
			this.compose(node)
		} else {
			node.forAllChilden( (node) =>  node.show(), false, (node) => node.parent.state.expand)
		}
		
		if(move)
			this.getStage().moveCenter(node.view )
		if(move || redraw)
			this.batchDraw()
		
		if(redraw)
			this.savePositions()
		
		return this
	}




	/**
	 * Свернуть ветку
	 * @param  Node  node
	 * @return this
	 */
	collapse(node) {
		node.removeStyle('expand')
		node.forAllChilden( (node) =>  node.hide(), false)
		node.state.expand = false
		if (! node.isRoot())  {
			this.getStage().moveCenter(node.parent.view)
		} else {
			this.batchDraw()
		}
		
		this.savePositions()
		
		return this
	}

	/**
	 * Установить позиции групп  относительно родителя
	 * @param  [] nodes
	 * @param Node parent родитель для группы
	 * @param POS_LEFT||POS_RIGHT  position   позиция относительно родителя
	 * @param {[type]} sign   [description]
	 */
	moveNodes(nodes, parent, position)  {


		let count = nodes.length
		let offsetY =   count * 40 / 2  - 20
		let max = Math.max(...nodes.map( (node) => node.width() ) )   // Найдем самую широкую ноду
		const kl = 15

		nodes.forEach( (node , i ) => {


			let y = i*40 - offsetY
			let x =  position== POS_LEFT  ?
						(parent.width())  +  count * kl :
						 -count*kl - max  - (node.width() - max)

			node.position({x:x + parent.x(),y:y + parent.y() }).show()

		})

		return this
	}
	/**
	 * Расставить узлы
	 * @param  {[type]} rootNode [description]
	 * @return {[type]}          [description]
	 */
	compose(node) {

		if ( node.isRoot() ) {
			let half = Math.floor(node.childs.length / 2)
			this.moveNodes(node.childs.slice(0,half),  node, POS_LEFT )
			this.moveNodes(node.childs.slice(half),  node, POS_RIGHT  )
		} else {
			this.moveNodes(node.childs,  node, node.signParentPositionX() < 1 ?  POS_LEFT : POS_RIGHT )
		}

		node.state.compose = true

		return this
	}


	/**
	 * Загрузка дерева
	 * @param  {[type]} json [description]
	 * @return {[type]}      [description]
	 */
	load(json) {

		this.reset()
		this.root =  Tree.load(json, (data) =>  new Node(data) )

		// инициализация все элементов отображения
		this.root.forAllChilden( node  => {

				this.add(node.view)
				node.init()
				
				
				if(node.expanded)
				{
				
					this.expand(node, false, false)
				}
			},
			true
		)


		if (! this.root.isVisible() ) { // корень не виден


			this.root.view.show().position({
				x: this.width()/2 - this.root.width()/2,
				y: this.height()/2
			})
			this.expand(this.root, false)

		} else {
			this.batchDraw()
		}


		this.root.forAllChilden( node  => {

			if(node.getPositionX() && node.getPositionY())
				node.position({x:node.getPositionX(), y:node.getPositionY()  })
			},
			true
		)



		this.batchDraw()
		
		
		
		
		return this

	}

	reset() {
		if (! this.root )  return
		this.root.forAllChilden(
			(node)  => {
				delete(node.view)
			},
			true
		)
		delete(this.root)
		this.destroyChildren()
		this.clear()
	}
	
	savePositions()
	{
		let positions = []
		this.root.forAllChilden(
			(node)  => {
				positions.push( {id:node.id, expand:node.state.expand, position:node.position()} )
			},
			true
		)
		
		/*axios.patch( `/seo/node/states/`,  positions)*/
		axios.post( `/seo/node/states`,  positions)
			.then(response => {
				console.log(response.data)
			})
	}

	/**
	 * Объект для выгрузки
	 * @return Object
	 */
	serialize() {
		return this.root.serialize()
	}


}