<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Заказчики</div>

                    <div class="card-body">
                        <h1>TODO
                          .</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
  }
</script>

<style lang="scss" scoped>
</style>

