<template>
	<b-navbar-nav >
		<b-button-group>
			<button v-on:click="$router.push({path:'/'})"  title='К списку' class='btn btn-sm btn-outline-dark'>
				<v-icon icon='category' />
			</button>
			<button v-on:click="$root.$emit('reload')"  title='Перегрузить' class='btn btn-sm btn-outline-dark'>
				<v-icon icon='synchronize' />
			</button>
		</b-button-group>
		<b-button-group class='ml-2'>
			<button v-on:click="$root.$emit('search')"  title='Поиск' class='btn btn-sm btn-outline-dark'>
				<v-icon icon='search' />
			</button>
		</b-button-group>
		<b-button-group class='ml-2'>


			<button v-on:click="$root.$emit('export')"  title='Экспорт' class='btn btn-sm btn-outline-dark'>
				<v-icon icon='export' />
			</button>
			<!--
			<button v-on:click="$root.$emit('test')"  title='Test' class='btn btn-sm btn-outline-dark'>
				<v-icon icon='export' />
			</button>
		-->
		</b-button-group>

			<span class="navbar-brand">{{title}}</span>


	</b-navbar-nav>
</template>

<script>
	export default {
		name: 'v-nav-tool',
		data() {
			return {
				title : ''
			}
		},
		mounted() {
			this.$root.$on('set-title', this.setTitle)
		},
		beforeDestroy() {
			this.$root.$off('set-title', this.setTitle)
		},
		methods: {
			setTitle(title) {
				this.title = title;
			}
		}
	}
</script>
<style>
.navbar-brand {margin-left:1em;}
</style>