var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper kanban" },
    [
      _c("div", { staticClass: "user" }, [
        _vm._v("\n        Пользователь: "),
        _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.user.name))]),
        _vm._v("("),
        _c("span", { attrs: { id: "user_id" } }, [_vm._v(_vm._s(_vm.user.id))]),
        _vm._v(")\n\t\t\t  \n      ")
      ]),
      _vm._v(" "),
      _vm.showSpinner
        ? _c("spinner")
        : [
            _c(
              "kanban-board",
              {
                attrs: {
                  stages: _vm.stages.map(function(e) {
                    return e.guid
                  }),
                  blocks: _vm.blocks
                },
                on: { "update-block": _vm.updateBlock }
              },
              [
                _vm._l(_vm.stages, function(stage) {
                  return _c(
                    "div",
                    {
                      key: stage.guid,
                      staticClass: "stages",
                      attrs: { slot: stage.guid },
                      slot: stage.guid
                    },
                    [
                      _c("div", { staticClass: "stage" }, [
                        _vm._v(
                          "\t\t\t  \n                " +
                            _vm._s(stage.name) +
                            " \n              "
                        )
                      ])
                    ]
                  )
                }),
                _vm._v(" "),
                _vm._l(_vm.blocks, function(block) {
                  return _c(
                    "div",
                    {
                      key: block.id,
                      attrs: { slot: block.id },
                      slot: block.id
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-tool",
                          attrs: {
                            href: "#",
                            "data-toggle": "modal",
                            "data-target": "#leadModal"
                          }
                        },
                        [
                          _c("div", { staticClass: "name" }, [
                            _c("div", { staticClass: "card-header" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(block.name) +
                                  "\n\t\t\t\t\t"
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "card-body" }, [
                              _c("span", [_vm._v(_vm._s(block.description))])
                            ])
                          ])
                        ]
                      )
                    ]
                  )
                })
              ],
              2
            )
          ],
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "leadModal",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "myModalLabel",
            "aria-hidden": "true"
          }
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: { click: _vm.setWinState }
                  },
                  [_vm._v("Win ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: { click: _vm.setLoseState }
                  },
                  [_vm._v("Lose ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.saveLead }
                  },
                  [_vm._v("Сохранить")]
                )
              ])
            ])
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("span", { staticClass: "block_id" }),
      _vm._v(" "),
      _c("h4", { staticClass: "modal-title", attrs: { id: "myModalLabel" } }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [
          _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")]),
          _c("span", { staticClass: "sr-only" }, [_vm._v("Закрыть")])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("div", { attrs: { id: "block-params" } }),
      _vm._v(" "),
      _c("span", [_vm._v("Описание:")]),
      _vm._v(" "),
      _c("textarea", {
        staticClass: "description",
        attrs: { id: "lead_text" }
      }),
      _vm._v(" "),
      _c("section", { staticClass: "comments", attrs: { id: "comments" } }),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("span", [_vm._v("Комментарий:")]),
      _vm._v(" "),
      _c("div", { staticClass: "comment-bock-new" }, [
        _c("textarea", { staticClass: "comment", attrs: { id: "comment_new" } })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }