var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "p-1  h-100  position-relative area",
      class: { "build-page": _vm.loading },
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.$root.$emit("keyup.esc")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-wrap  align-content-start" },
        [
          _vm._l(_vm.$store.state.dashboard.list, function(model) {
            return _vm.$store.getters.isLoggedIn
              ? _c("tile", { key: model.id, attrs: { model: model } })
              : _vm._e()
          }),
          _vm._v(" "),
          !_vm.loading ? _c("upload") : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("share-form", { ref: "share-form" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }