var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "node-info" }, [
    _c("div", { staticClass: "header" }, [
      _vm._v("\n\t\t" + _vm._s(_vm.model.name) + "\n\t")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "data section", class: { "build-page": _vm.loading } },
      [
        !_vm.loading && _vm.model.data
          ? _c("div", { staticClass: "data-table" }, [
              _c(
                "table",
                { staticClass: "table table-sm" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._l(_vm.model.data.data, function(data) {
                    return _c("tr", [
                      _c("td", [_vm._v(_vm._s(data.title))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(data.data[0]))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(data.data[1]))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(data.data[2]))])
                    ])
                  })
                ],
                2
              )
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "comment" })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", [_vm._v("Фраза")]),
        _vm._v(" "),
        _c("th", [_vm._v("Базовая частотность"), _c("br")]),
        _vm._v(" "),
        _c("th", [_vm._v('Частотность " "')]),
        _vm._v(" "),
        _c("th", [_vm._v('Частотность "!"')])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }