<template>
    <div class="content-wrapper kanban">
      <div class="user">
        Пользователь: <span class="name">{{user.name}}</span>(<span id="user_id">{{user.id}}</span>)
			  
      </div>

      <spinner v-if="showSpinner" />
      <template v-else>

        <kanban-board

            :stages="stages.map(e => e.guid)"
            :blocks="blocks"
            @update-block="updateBlock"
         >
          <div v-for="stage in stages" :slot="stage.guid" :key="stage.guid" class="stages">
              <div class="stage">			  
                {{ stage.name }} 
              </div>
          </div>
          <div v-for="block in blocks" :slot="block.id" :key="block.id">
			<a href="#" class="btn btn-tool" data-toggle="modal" data-target="#leadModal" >
				<div class="name">
					<div class="card-header">
						{{ block.name }}
					</div>
					<div class="card-body">
						<span>{{ block.description }}</span>
					</div>
				</div>
			</a>

          </div>
        </kanban-board>
      </template>
   


		<!-- Modal -->
		<div class="modal fade" id="leadModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
			<div class="modal-content">
			  <div class="modal-header">
				<span class="block_id"></span>
				<h4 class="modal-title" id="myModalLabel"></h4>
				<button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
			  </div>
			  
			  <div class="modal-body">
			  
				<div id="block-params"></div>
			  
				<span>Описание:</span>
				<textarea class="description" id="lead_text" ></textarea>

				<section class="comments" id="comments"></section>
				<hr/>
				<span>Комментарий:</span>
				<div class="comment-bock-new">
					<textarea class="comment" id="comment_new"></textarea>			
				</div>
			  </div>
			  
			  <div class="modal-footer">
				<button type="button" class="btn btn-secondary" v-on:click="setWinState">Win </button>
				<button type="button" class="btn btn-secondary" v-on:click="setLoseState">Lose </button>
				<button type="button" class="btn btn-primary" v-on:click="saveLead">Сохранить</button>
			  </div>
			</div>
		  </div>
		</div>	  
	
	
    </div>

	
	
</template>


<script>
import axios from  'axios'
import spinner from "./spinner";
const REFRESH_TIME = 1000 * 5 // 5 секунд


export default {
    name: "LeadPage",
    components:{spinner},
    data(){
      return {
        showSpinner: false,
        blocks: [],
        timer: null,
        stages: []
      }

    },

    mounted() {

		$('#leadModal').on('show.bs.modal', function (event) {
		
			$('#comments').html('');
			$('#comment_new').val("");
			
			var modal = $(this)
			
			modal.find('.modal-title').text('')
			modal.find('.modal-body .description').val('')
		
			var button = $(event.relatedTarget) 
			var id = button.parent().parent().attr('data-block-id')			
			
			axios.get(`/api/lead/${id}`).then( response => {
				//console.log(response.data);		
				modal.find('.block_id').text(id);
				modal.find('.modal-title').text(response.data.name)
				modal.find('.modal-body .description').val(response.data.description)
				
				
				var html = '<div class="row">'

				html += '<div class="col-sm-4 form-group param-name">'
				
					html += '<div class="form-group"><span>Телефон</span></div>'
					html += '<div class="form-group"><span>Email</span></div>'

				html += '</div>'
				html += '<div class="col-sm-8 form-group param-value">'
				
					html += '<div class="form-group"><span>'+ response.data.phone +'</span></div>'
					html += '<div class="form-group"><span>'+ response.data.email +'</span></div>'
				
				html += '</div></div>'
				
				$("#block-params").html(html);
				
			})

			axios.get(`/api/lead_comments/${id}`).then( response => {

				response.data.forEach(function(comment) {
					//console.log(comment);
					
					var html = '<div class="comment-block"><div class="comment-header"><span class="date">'+comment.createdate+'</span><span class="user">'+comment.name+'</span></div><div class="comment">'+comment.comment+'</div></div>'
					$('#comments').append(html)					
				});
			})
		})			
	
		this.loadStages()
        this.fetchBlocks(true)
        this.timer = window.setInterval(()=>{
          this.fetchBlocks()
        }, REFRESH_TIME)
    },

    beforeDestroy() {
      this.stopReload()
    },

    methods:{
		updateBlock(id, status) {
			this.blocks.find(b => b.id === Number(id)).status = status;
			this.putBlocks(id, status)
		},

		stopReload() {
			this.timer && window.clearInterval(this.timer)
		},


		loadStages() {
			axios.get('/api/lead_statuses').then( response => {
			  this.stages = response.data
			})
		},


		/**
		* Загрузка блоков
		*/
		fetchBlocks(mute = false){

		// Отобразить спинер только при первоначальной загрузке
		mute && (this.showSpinner = true)


		axios.get('/api/leads').then( response => {
			  this.blocks = response.data
			}).finally(() => this.showSpinner = false)
		},


		putBlocks(id, status) {
			axios.put(`/api/lead/${id}`, {status:status}).then( response => {})
		},
		
		setWinState: function (event) {
			var id = $('#leadModal').find('.block_id').text();
			axios.put(`/api/lead/${id}`, {status:'win'}).then( response => {
				this.fetchBlocks();
				$('#comment_new').val("");
				$("#leadModal").modal('hide');
			})
			
		},

		setLoseState: function (event) {
			var id = $('#leadModal').find('.block_id').text();
			axios.put(`/api/lead/${id}`, {status:'lose'}).then( response => {
				this.fetchBlocks();
				$('#comment_new').val("");
				$("#leadModal").modal('hide');			
			})
			
		},

		saveLead: function (event) {

			var lead_id = $('#leadModal').find('.block_id').text();
			var description = $('#leadModal').find('.description').val();
			
			var comment_new = $('#comment_new').val();
			
			//console.log(comment_new);
			
			if(comment_new != "")
			{
				var user_id = $("#user_id").text();
				//console.log("user_id: "+user_id);
				
				axios.put(`/api/lead_comments/${lead_id}`, {comment:comment_new, lead_id:lead_id, user_id:user_id}).then( response => {		
					$('#comment_new').val("");
				})
			}
			
			axios.put(`/api/lead/${lead_id}`, {description:description}).then( response => {

				$(".drag-container").find("[data-block-id="+lead_id+"]").find(".card-body").html(description);
				$("#leadModal").modal('hide');
			
			})
		}
    },
    computed:{
        user() {
          return window.user
        }
    }
}
</script>
<style lang="scss">
  @import "../../sass/kanban.scss"; 
</style>



