var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card m-1" }, [
    _c(
      "div",
      { staticClass: "card-body", class: { "bg-secondary": _vm.drag } },
      [
        _vm.upload
          ? _c(
              "div",
              { staticClass: "text-center " },
              [
                _c("b-spinner", {
                  staticStyle: { width: "3rem", height: "3rem" }
                }),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.percent))])
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.upload
          ? _c(
              "label",
              {
                on: {
                  dragover: function($event) {
                    $event.preventDefault()
                    _vm.drag = true
                  },
                  dragleave: function($event) {
                    _vm.drag = false
                  },
                  drop: _vm.drop
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "text-center " },
                  [
                    _c("v-icon", { attrs: { icon: "upload", size: "50" } }),
                    _vm._v(" "),
                    _c("div", [_vm._v("кликните,  или перетащите сюда файл ")]),
                    _vm._v(" "),
                    !_vm.drag
                      ? _c("input", {
                          attrs: { type: "file", accept: ".xlsx" },
                          on: { change: _vm.change }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }