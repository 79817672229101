"use strict";
const Konva     = require('konva')
//import logo_url  from './../assets/lum-logo.png'

const SCALE_STEP  = 1.1;
const SCALE_MAX  = 5;
const SCALE_MIN  = 0.5;

export default class Stage  extends  Konva.Stage{

    constructor(option) {
        super(option)
        this.saved = []
        let canvas = this.container()
        //canvas.style['background'] = `url(${logo_url}) no-repeat 10px 10px `
        this.draggable(true)
        this.select_mode = false;
        this.start_select = {}


        this.on('mousedown', (evt) => {
            if (evt.target == this) {

                if (evt.evt.ctrlKey) {
                    this.draggable(false)
                    this.select_mode = true

                    this.startPosition = {
                        x: this.getPointerPosition().x/ this.scaleX() - this.position().x / this.scaleX(),
                        y: this.getPointerPosition().y/ this.scaleX() - this.position().y / this.scaleX() ,
                    }

                    evt.startPosition = this.startPosition

                    this.getChildren().fire('selectStart', evt)
                } else {
                      this.getChildren().fire('stageClick', evt)
                }

            }

        })

        this.on('mouseup', (evt) => {
            if (this.select_mode) {
                this.draggable(true)
                this.getChildren().fire('selectEnd', evt)
                this.select_mode = false
            }
        })

        this.on('mousemove', (evt) => {
            if (this.select_mode) {
                if (! evt.evt.ctrlKey) {
                    this.getChildren().fire('selectEnd', evt)
                    this.select_mode = false
                } else {
                    evt.startPosition = this.startPosition
                    evt.currentPosition = {
                        x: this.getPointerPosition().x/ this.scaleX() - this.position().x / this.scaleX(),
                        y: this.getPointerPosition().y/ this.scaleX() - this.position().y / this.scaleX() ,
                    }
                    this.getChildren().fire('selectMove', evt)
                }
            }
        })


        this.on('dragstart', (eve) => {
            this.saved = []
        })

        this.on('dragmove', (evt) => {
            this.saved.push({ x: this.x(), y: this.y(), time: performance.now() })
            if (this.saved.length > 60)
            {
                this.saved.splice(0, 30)
            }
        })

        this.on('dragend', (evt) => {

            if (this.saved.length)
            {
                const last = this.saved[this.saved.length - 1]
                const now = performance.now()
                for (let save of this.saved)
                {
                    if (save.time >= now - 100)
                    {
                        const time = now - save.time
                        this.to ( {
                            x: this.x() +  (this.x() - save.x) / time  * 100 ,
                            y: this.y() + (this.y() - save.y) / time  *100,
                            easing: Konva.Easings.EaseOut
                        })
                        break
                    }
                }

            }

        })


        this.on('wheel', e => {
            e.evt.preventDefault();
            let oldScale = this.scaleX();

            let mousePointTo = {
              x: this.getPointerPosition().x/oldScale - this.x() / oldScale  ,
              y: this.getPointerPosition().y/oldScale - this.y() / oldScale
            };


            let newScale = e.evt.deltaY < 0 ? oldScale * SCALE_STEP : oldScale / SCALE_STEP;

            if ( newScale > SCALE_MAX  ||  newScale < SCALE_MIN)  return

            this.scale({ x: newScale, y: newScale });

            let newPos = {
              x:
                -(mousePointTo.x  - this.getPointerPosition().x / newScale) *newScale,
              y:
                -(mousePointTo.y - this.getPointerPosition().y / newScale) *newScale
            };
            this.position(newPos);


            this.getLayers().fire('resize', this  )
            this.batchDraw()


      });



    }


    moveCenter(to, smooth  = true) {


        const  offset  =  to instanceof Konva.Node   ?
            {
                x: to.absolutePosition().x + to.width()    ,
                y: to.absolutePosition().y + to.height()
            } : to

        const  pos =  {
            x: this.x() + this.width() /2  - offset.x,
            y: this.y() + this.height() /2 - offset.y
        }
        if (! smooth  ) {
            this.move(pos)
            this.batchDraw();
        } else {
            this.to(pos)
        }

    }


    reset() {
        return this.destroyChildren().position({x:0,y:0}).scaleX(1).scaleX(1)
    }

    setPointer(pointer) {
        this.container().style.cursor = pointer;
        return this
    }




}