var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper kanban" },
    [
      _c("div", { staticClass: "user" }, [
        _vm._v("\n        Менеджер: "),
        _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.user.name))]),
        _vm._v("("),
        _c("span", { attrs: { id: "user_id" } }, [_vm._v(_vm._s(_vm.user.id))]),
        _vm._v(")\n\t\t\t  \n      ")
      ]),
      _vm._v(" "),
      _vm.showSpinner
        ? _c("spinner")
        : [
            _c(
              "kanban-board",
              {
                attrs: {
                  stages: _vm.stages.map(function(e) {
                    return e.guid
                  }),
                  blocks: _vm.blocks
                },
                on: { "update-block": _vm.updateBlock }
              },
              [
                _vm._l(_vm.stages, function(stage) {
                  return _c(
                    "div",
                    {
                      key: stage.guid,
                      staticClass: "stages",
                      attrs: { slot: stage.guid },
                      slot: stage.guid
                    },
                    [
                      _c("div", { staticClass: "stage" }, [
                        _vm._v(
                          "\t\t\t  \n                " +
                            _vm._s(stage.name) +
                            " \n              "
                        )
                      ])
                    ]
                  )
                }),
                _vm._v(" "),
                _vm._l(_vm.blocks, function(block) {
                  return _c(
                    "div",
                    {
                      key: block.id,
                      attrs: { slot: block.id },
                      slot: block.id
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-tool",
                          attrs: {
                            href: "#",
                            "data-toggle": "modal",
                            "data-target": "#leadModal"
                          }
                        },
                        [
                          _c("div", { staticClass: "name" }, [
                            _c("div", { staticClass: "card-header" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\tЗаказ № " +
                                  _vm._s(block.id) +
                                  "\n\t\t\t\t\t"
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "card-body" }, [
                              _c("div", [
                                _vm._v("Покупатель: "),
                                _c("span", { staticClass: "order-param" }, [
                                  _vm._v(_vm._s(block.customer_name))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v("Адрес доставки:"),
                                _c(
                                  "span",
                                  { staticClass: "order-param address" },
                                  [_vm._v(_vm._s(block.address))]
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    ]
                  )
                })
              ],
              2
            )
          ],
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "leadModal",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "myModalLabel",
            "aria-hidden": "true"
          }
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: { click: _vm.setWinState }
                  },
                  [_vm._v("Win ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: { click: _vm.setLoseState }
                  },
                  [_vm._v("Lose ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.saveOrder }
                  },
                  [_vm._v("Сохранить")]
                )
              ])
            ])
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("span", { staticClass: "block_id" }),
      _vm._v(" "),
      _c("h4", { staticClass: "modal-title", attrs: { id: "myModalLabel" } }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [
          _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")]),
          _c("span", { staticClass: "sr-only" }, [_vm._v("Закрыть")])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("div", { staticClass: "card card-primary card-tabs" }, [
        _c("div", { staticClass: "card-header p-0 pt-1" }, [
          _c(
            "ul",
            {
              staticClass: "nav nav-tabs",
              attrs: { id: "custom-tabs-one-tab", role: "tablist" }
            },
            [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link active",
                    attrs: {
                      id: "custom-tabs-one-home-tab",
                      "data-toggle": "pill",
                      href: "#custom-tabs-one-home",
                      role: "tab",
                      "aria-controls": "custom-tabs-one-home",
                      "aria-selected": "true"
                    }
                  },
                  [_vm._v("Контактные данные")]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      id: "custom-tabs-one-profile-tab",
                      "data-toggle": "pill",
                      href: "#custom-tabs-one-profile",
                      role: "tab",
                      "aria-controls": "custom-tabs-one-profile",
                      "aria-selected": "false"
                    }
                  },
                  [_vm._v("Товары")]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      id: "custom-tabs-one-messages-tab",
                      "data-toggle": "pill",
                      href: "#custom-tabs-one-messages",
                      role: "tab",
                      "aria-controls": "custom-tabs-one-messages",
                      "aria-selected": "false"
                    }
                  },
                  [_vm._v("Комментарии")]
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            {
              staticClass: "tab-content",
              attrs: { id: "custom-tabs-one-tabContent" }
            },
            [
              _c("div", {
                staticClass: "tab-pane fade show active",
                attrs: {
                  id: "custom-tabs-one-home",
                  role: "tabpanel",
                  "aria-labelledby": "custom-tabs-one-home-tab"
                }
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "tab-pane fade",
                attrs: {
                  id: "custom-tabs-one-profile",
                  role: "tabpanel",
                  "aria-labelledby": "custom-tabs-one-profile-tab"
                }
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "tab-pane fade",
                attrs: {
                  id: "custom-tabs-one-messages",
                  role: "tabpanel",
                  "aria-labelledby": "custom-tabs-one-messages-tab"
                }
              })
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }