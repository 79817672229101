var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card m-1 tile", class: { editable: _vm.model.editable } },
    [
      _c(
        "div",
        { staticClass: "card-body", on: { dblclick: _vm.edit } },
        [
          _vm.self
            ? _c(
                "div",
                { staticClass: "position-absolute controls edit-button" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.editabled,
                          expression: "! editabled"
                        }
                      ],
                      attrs: {
                        size: "sm",
                        variant: "light",
                        title: "Редактировать"
                      },
                      on: { click: _vm.edit }
                    },
                    [_c("v-icon", { attrs: { icon: "edit" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editabled,
                          expression: "editabled"
                        }
                      ],
                      attrs: {
                        size: "sm",
                        variant: "outline-primary",
                        title: "Сохранить"
                      },
                      on: { click: _vm.save }
                    },
                    [_c("v-icon", { attrs: { icon: "save" } })],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.self
            ? _c("v-icon", {
                staticClass: "share-i  p-1",
                attrs: { icon: "share" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.self && _vm.model.shared
            ? _c("v-icon", {
                staticClass: "share-i  p-1 s20",
                attrs: { icon: "user", title: "Доступно другим пользователям " }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h5", { staticClass: "card-title" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.editabled,
                    expression: "! editabled"
                  }
                ],
                on: { click: _vm.editor }
              },
              [_vm._v(_vm._s(_vm.model.name))]
            ),
            _vm._v(" "),
            _vm._v("\n\t\t\t/b-input>\n\n\t\t")
          ]),
          _vm._v(" "),
          !_vm.editabled
            ? _c("p", { staticClass: "card-text" }, [
                _vm._v(_vm._s(_vm.model.description))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("b-textarea", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editabled,
                expression: "editabled"
              }
            ],
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape"
                  ])
                ) {
                  return null
                }
                return _vm.cancel($event)
              }
            },
            model: {
              value: _vm.model.description,
              callback: function($$v) {
                _vm.$set(_vm.model, "description", $$v)
              },
              expression: "model.description"
            }
          }),
          _vm._v(" "),
          !_vm.editabled
            ? _c(
                "div",
                {
                  staticClass:
                    "position-absolute small bg-light w-100 info-panel b-1"
                },
                [
                  _c("span", { staticClass: "p-2" }, [
                    _vm._v(
                      "\n\t\t\t\tСоздано:\n\t\t\t\t" +
                        _vm._s(_vm.created) +
                        "\n\t\t\t"
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "m-2" }, [
                    _vm._v(
                      "\n\t\t\t\tКластеров:\n\t\t\t\t" +
                        _vm._s(_vm.model.meta.clusters) +
                        "\n\t\t\t"
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.model.meta.request,
                          expression: "model.meta.request"
                        }
                      ],
                      staticClass: "m-2"
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\tЗапросов:\n\t\t\t\t" +
                          _vm._s(_vm.model.meta.request) +
                          "\n\t\t\t"
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.editabled
            ? _c(
                "div",
                {
                  staticClass:
                    "controls action-buttons position-absolute text-center "
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "light", title: "Дерево" },
                      on: { click: _vm.tree }
                    },
                    [_c("v-icon", { attrs: { icon: "active-directory" } })],
                    1
                  ),
                  _vm._v(" "),
                  _vm.self
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            size: "sm",
                            variant: "light",
                            title: "Поделиться"
                          },
                          on: { click: _vm.share }
                        },
                        [_c("v-icon", { attrs: { icon: "share" } })],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        size: "sm",
                        variant: "light",
                        title: "Подробнее"
                      },
                      on: { click: _vm.info }
                    },
                    [_c("v-icon", { attrs: { icon: "info" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "light", title: "Удалить" },
                      on: { click: _vm.remove }
                    },
                    [_c("v-icon", { attrs: { icon: "delete" } })],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }