"use strict";

import Query from'./query.js'
import Node from './node.js'

function isset (accessor) {
  try {
    // Note we're seeing if the returned value of our function is not
    // undefined or null
    return accessor !== undefined && accessor !== null
  } catch (e) {
    // And we're able to catch the Error it would normally throw for
    // referencing a property of undefined
    return false
  }
}

export default class Tree   {

	static load(json_data,  NodeConstructor = (data) =>   new  Node(data.title) ) {
		return this.create(json_data, NodeConstructor)
	}

	static create(data, NodeConstructor  ) {
		let node = NodeConstructor(data)
		node.setId(data.id)
		node.hasData(data.hasdata)
		if(isset(data.options)){
			
			if(isset(data.options.kanva_expand))
				node.setExpanded(data.options.kanva_expand )
			if(isset(data.options.position))
				node.setPosition(data.options.position)
		}
		data.children.forEach( data => node.addChild( this.create(data, NodeConstructor) ) )
		return node
	}
}