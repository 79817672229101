var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "spinner", class: { fade: _vm.fade } }, [
    _c(
      "svg",
      {
        attrs: {
          width: "76",
          height: "76",
          viewBox: "0 0 76 76",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }
      },
      [
        _c("path", {
          attrs: {
            d:
              "M38 3C46.0974 3 53.9444 5.80768 60.2038 10.9446C66.4632 16.0816 70.7478 23.23 72.3275 31.1718C73.9072 39.1137 72.6843 47.3576 68.8672 54.4989C65.0501 61.6402 58.875 67.237 51.3939 70.3358C43.9129 73.4345 35.5888 73.8435 27.84 71.4929C20.0913 69.1423 13.3973 64.1777 8.89856 57.445C4.39987 50.7122 2.37485 42.6278 3.16854 34.5694C3.96223 26.5109 7.52552 18.977 13.2513 13.2513",
            stroke: "lightgrey",
            "stroke-width": "6"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }