"use strict";


const Konva     = require('konva') 

/**
 *  Линия соединения 
 */

const STROKE  = '#266d14'
const WIDTH  = 2

module.exports = class Compound   extends Konva.Line {
	
	constructor (node, config) {
		super({bezier: true,visibe:false,listening:false,stroke: STROKE,strokeWidth:WIDTH, ...config})  
		this.node  = node
	} 

	setActivePath() {
		this.setAttrs({strokeWidth: 3})
	} 

	unsetActivePath() {
		this.setAttrs({strokeWidth: 1})
	}


	getPointsFrom() {
		let  x,  y =  this.node.parent.getYCenter()  
		if (this.node.signParentPositionX() == 1 ) {
		 	x = this.node.parent.position().x
		} else {
			x = this.node.parent.position().x + this.node.parent.width()
		} 		
		return {x:x,y:y}
	} 

	getPointsTo() {
		let x, y  = this.node.getYCenter()  
	
		if (this.node.signParentPositionX() !=  1 ) {
		 	x = this.node.position().x
		} else {
			x = this.node.position().x + this.node.width()
		} 		
		return {x:x,y:y}
	} 

	getPoints() {

		let from = this.getPointsFrom()
		let to   = this.getPointsTo()
		let ck = Math.abs(from.x - to.x)  * .3

		if (this.node.signParentPositionX() > 0 ) {
			[from, to]  =  [to, from]
		}
		let  points  = [
			from.x,  
			from.y, 
			from.x + ck  ,  
			from.y, 
			to.x - ck,  
			to.y, 
			to.x,  
			to .y, 
		]
		
		return points
	}

	redraw() {
		this.points( this.getPoints() ) 
	}

}