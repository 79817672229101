<template>
	<div class='vtl'>
		<div v-if="model.pid !== 0" >
			<div class="vtl-border vtl-up"
			:class="{'vtl-active': isDragEnterUp}"
			@drop="dropBefore"
			@dragenter="dragEnterUp"
			@dragover='dragOverUp'
			@dragleave="dragLeaveUp">
		</div>
		<div :id='"node_"+ model.id'
		:class="treeNodeClass"
		:draggable="!model.dragDisabled"
		@dragstart='dragStart'
		@dragover='dragOver'
		@dragenter='dragEnter'
		@dragleave='dragLeave'
		@drop='drop'
		@dragend='dragEnd'
		@mouseover='mouseOver'
		@mouseout='mouseOut'
		@dblclick.stop='click'>

		<span class="vtl-caret vtl-is-small" v-if="model.isLast && ! model.isLast()" @click.prevent.stop="toggle">
			<v-icon :icon='caretClass'  class="s20"  />
		</span>

		<div class="vtl-node-content"  v-if="!editable"  >
			{{model.name}}
		</div>
		<input  class="vtl-input"
		type="text"
		ref="nodeInput"
		v-if="editable"
		:value="model.name"
		v-on:keyup.enter='updateName'
		v-on:keyup.esc='setUnEditable'
		@blur="setUnEditable" />
		<div class="vtl-operation" v-show="isHover">
			<span title="Информация" @click.stop.prevent="infoNode">
				<slot name="infoNode">
					<v-icon icon='info'  class="s20"/>
				</slot>
			</span>
			<span title="Редактировать" @click.stop.prevent="setEditable" v-if="!model.editNodeDisabled">
				<slot name="editNode">
					<v-icon icon='edit'  class="s20"/>
				</slot>
			</span>

			<span title="Удалить" @click.stop.prevent="delNode" v-if="!model.delNodeDisabled">
				<slot name="delNode">
					<v-icon icon='trash'  class="s20"/>
				</slot>
			</span>
		</div>
	</div>

	<div v-if="model.children && model.children.length > 0 && expanded"
		class="vtl-border vtl-bottom"
		:class="{'vtl-active': isDragEnterBottom}"
		@drop="dropAfter"
		@dragenter="dragEnterBottom"
		@dragover='dragOverBottom'
		@dragleave="dragLeaveBottom"></div>
	</div>

	<div :class="{'vtl-tree-margin': model.name !== 'root'}" v-show="model.pid === 0 || expanded" v-if="isFolder">
		<item v-for="model in model.children"
		:default-tree-node-name="defaultTreeNodeName"
		:default-leaf-node-name="defaultLeafNodeName"
		v-bind:default-expanded="defaultExpanded"
		:model="model"
		:key='model.id'>
		<slot name="addTreeNode" slot="addTreeNode" />
		<slot name="addLeafNode" slot="addLeafNode" />
		<slot name="editNode" slot="editNode" />
		<slot name="delNode" slot="delNode" />
	</item>
</div>
</div>
</template>

<script>
	import { Tree, TreeNode } from './Tree.js'
	import { addHandler, removeHandler } from './tools.js'

	let compInOperation = null

	export default {
		data: function () {
			return {
				isHover: false,
				editable: false,
				isDragEnterUp: false,
				isDragEnterBottom: false,
				isDragEnterNode: false

			}
		},
		props: {
			model: {
				type: Object
			},
			defaultLeafNodeName: {
				type: String,
				default: 'New leaf node'
			},
			defaultTreeNodeName: {
				type: String,
				default: 'New tree node'
			},
			defaultExpanded: {
				type: Boolean,
				default: true
			}
		},

		computed: {

			expanded: {
				get() {
					return  this.model.expanded
				},
				set(val) {
					this.model.expanded = val
				}
			},

			itemIconClass () {
				return this.model.isLeaf ? 'icon-file' : 'icon-folder'
			},

			caretClass () {
				return this.expanded ? 'down-squared--v1' : 'right-squared--v1'

			},

			isFolder () {
				return this.model.children &&
				this.model.children.length
			},
			isCurrent() {
				return this.model.active == true
			},
			isDeleted() {
				return this.model.deleted == true
			},
			treeNodeClass () {
				const {
					model: {
						dragDisabled,
						disabled,
						active,
						deleted
					},

					isDragEnterNode,

				} = this

				return {
					'vtl-tree-node': true,
					'vtl-active': isDragEnterNode,
					'vtl-drag-disabled': dragDisabled,
					'vtl-disabled': disabled,
					'vtl-current': active,
					'vtl-deleted': deleted
				}
			}
		},
		mounted () {
			const vm = this
			addHandler(window, 'keyup', function (e) {
				if (e.keyCode === 13 && vm.editable) {
					//vm.editable = false
				}
			})
		},
		beforeDestroy () {
			removeHandler(window, 'keyup')
		},
		methods: {
			updateName (e) {
				//var oldName = this.model.name;
				this.model.changeName(e.target.value)
				var root = this.getRootNode();
				this.editable = false
				root.$emit('change-name', {'id': this.model.id, 'name': this.model.name})

			},

			delNode () {
				this.model.deleted = ! this.model.deleted

				var node = this.getRootNode()
				node.$emit('delete-node', this.model)
			},

			setEditable () {
				this.editable = true
				this.$nextTick(() => {
					const $input = this.$refs.nodeInput
					$input.focus()
					$input.setSelectionRange(0, $input.value.length)
				})
			},

			setUnEditable () {

				this.editable = false
			},

			toggle() {
				if (this.isFolder) {
					this.expanded = !this.expanded
					var root = this.getRootNode();
					root.$emit('toggle', {id: this.model.id, expanded:this.expanded})
				}
			},

			mouseOver(e) {
				if (this.model.disabled) return
					this.isHover = true
			},

			mouseOut(e) {
				this.isHover = false
			},

			click() {
				//var root = this.getRootNode()
				//root.$emit('click', this);
			},

			infoNode() {
				var root = this.getRootNode();
				root.$emit('info', this)
			},

			addChild(isLeaf) {
				const name = isLeaf ? this.defaultLeafNodeName : this.defaultTreeNodeName
				this.expanded = true
				var node = new TreeNode({ name, isLeaf })
				this.model.addChildren(node, true)
				var root = this.getRootNode();
				root.$emit('add-node', node)
			},

			dragStart(e) {
				if (!(this.model.dragDisabled || this.model.disabled)) {
					compInOperation = this
			// for firefox
			e.dataTransfer.setData("data","data");
			e.dataTransfer.effectAllowed = 'move'
			return true
		}
		return false
	},
	dragEnd(e) {
		compInOperation = null
	},
	dragOver(e) {
		e.preventDefault()
		return true
	},
	dragEnter(e) {
		if (!compInOperation) return
			if (this.model.isLeaf) return
				this.isDragEnterNode = true
		},
		dragLeave(e) {
			this.isDragEnterNode = false
		},
		drop(e) {

			if (!compInOperation) return
				const oldParent = compInOperation.model.parent;
			compInOperation.model.moveInto(this.model)
			this.isDragEnterNode = false
			var node = this.getRootNode();
			node.$emit('drop', {target: this.model, node: compInOperation.model, src: oldParent})
		},

		dragEnterUp () {
			if (!compInOperation) return
				this.isDragEnterUp = true
		},
		dragOverUp (e) {
			e.preventDefault()
			return true
		},
		dragLeaveUp () {
			if (!compInOperation) return
				this.isDragEnterUp = false
		},
		dropBefore () {
			if (!compInOperation) return
				const oldParent = compInOperation.model.parent;
			compInOperation.model.insertBefore(this.model)
			this.isDragEnterUp = false
			var node = this.getRootNode();
			node.$emit('drop-before', {target: this.model, node: compInOperation.model, src: oldParent})
		},

		dragEnterBottom () {
			if (!compInOperation) return
				this.isDragEnterBottom = true
		},
		dragOverBottom (e) {
			e.preventDefault()
			return true
		},
		dragLeaveBottom () {
			if (!compInOperation) return
				this.isDragEnterBottom = false
		},
		dropAfter () {
			if (!compInOperation) return
				const oldParent = compInOperation.model.parent;
			compInOperation.model.insertAfter(this.model)
			this.isDragEnterBottom = false
			var node = this.getRootNode();
			node.$emit('drop-after', {target: this.model, node: compInOperation.model, src: oldParent})
		},
		getRootNode() {
			var  node =  this
			while ( node.model instanceof TreeNode  && node.model.pid  ) {
				node = node.$parent
			}
			return node;
		}
	},
	beforeCreate () {
		this.$options.components.item = require('./VueTreeList.vue').default
	}
}
</script>

<style >

.vtl-icon {
	color:#0052ff;
	cursor:pointer;
	&.vtl-menu-icon {
		margin-right: 4px;
		&:hover {
			color: inherit;
		}
	}
	&:hover {
		color: blue;
	}
}



.vtl-border {
	height: 5px;
	&.vtl-up {
		margin-top: -5px;
		background-color: transparent;
	}
	&.vtl-bottom {
		background-color: transparent;
	}
	&.vtl-active {
		border-bottom: 3px dashed blue;
		/*background-color: blue;*/
	}
}

.vtl-tree-node {
	display: flex;
	align-items: center;
	padding: 5px 0 5px 1rem;
	.vtl-input {
		border: none;
		max-width: 150px;
		border-bottom: 1px solid blue;
	}
	&:hover {
		background-color: rgba(#0052ff,0.1);
	}
	&.vtl-active {
		outline: 2px dashed pink;
	}
	.vtl-caret {
		margin-left: -1rem;
		margin-right: 0.2rem;


	}
	.vtl-operation {
		margin-left: 2rem;
		letter-spacing: 1px;

		img {
			cursor:pointer;
			&:hover {
				margin-top:-5px;
			}

		}
	}
}

.vtl-current {
	font-weight:600;
	background-color:rgba(0, 82, 255, 0.1);;
}
.vtl-deleted {
	color:#aaa;
	font-weight:100;
}

.vtl-item {
	cursor: pointer;
}
.vtl-tree-margin {
	margin-left: 2em;
}
</style>
