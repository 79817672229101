<template>


	<div class='h-100 '>
	
		<b-navbar-nav >
			<b-button-group>
				<button v-on:click="reset()"  title='Сбросить' class='btn btn-sm btn-outline-dark'>
					<v-icon icon='synchronize' />
				</button>
			</b-button-group>
		</b-navbar-nav>
	
		<div id='tree-canvas'  class='h-100'v-bind:class="{ 'build-page' : loading }"></div>

		<search v-if='doSearch'></search>

		<b-modal title="Экспорт" ref="export-form" v-b-modal.modal-xl>
			<b-form-group>
				<b-form-textarea v-model="exportText" rows='15'  wrap="off">
				</b-form-textarea>
			</b-form-group>
		</b-modal>
		
		
	</div>

</template>

<script>

	import treeEditor from './editor/editor.js'
	const  fileDownload = require('./file-download.js')

	import tools  from './nav-tool.vue'
	import search  from './search.vue'


export default {
	name: 'editor',
	treeEditor: null,
	timer : null,
	components: {
		'search': search
	},

	data() {
		return {
			loading: false,
			id : this.$route.params.id,
			exportText: '',
			doSearch: false
		}
	},


	beforeDestroy() {
		this.store()

		this.$store.commit('submenu',null)
		this.$root.$off('center')
		this.$root.$off('reload')
		this.$root.$off('export' )
		this.$root.$off('logout' )
		this.$root.$off('search' )


		window.removeEventListener("keydown", this.keyBindings)
		window.onbeforeunload = null

		this.treeEditor = null


	},
	mounted() {

		window.onbeforeunload = () => this.store()

		this.$store.commit('submenu',tools)
		this.$root.$on('logout', this.store)
		this.$root.$on('center', this.moveCenter)
		this.$root.$on('reload', this.reload)
		this.$root.$on('export', this.export)
		this.$root.$on('search', this.switchSearch)

		window.addEventListener("keydown", this.keyBindings.bind(this));

		this.load()

	},

	methods: {

		store () {
			if (this.$store.getters.isLoggedIn && this.treeEditor ) {
				let data = this.treeEditor.serialize()
				this.$http.put( 'tree/'+this.id,data)
				.catch(error => this.$root.$emit('error', error.response.data.message))
			}
		},
		
		
		reset() {

			this.$http.post( '/seo/reset')
			.then(response =>  {
				console.log("Reset!");
				this.loadTree()
			})					
		},
		
    /**
     * Построить дерево
     * @param tree
     */
    makeTree(tree) {
	  console.log("tree:")
      console.log(tree)
      let _container =  document.getElementById('tree-canvas')
      this.treeEditor = new treeEditor({
        container : '#tree-canvas',
        width: _container.clientWidth ,
        height: _container.clientHeight,
      })
      .load(tree)
    },
    /**
     * Загрузить дерево
     * @param root
     */
    loadTree(root) {
      this.loading  = true
      //this.$http.get('/node/'+root)
	      //.then(response => {
		  //		this.makeTree(response.data)
          //})
		  
		this.$http.post( '/seo/nodes')
		.then(response =>  {
			this.makeTree(response.data)
		})			
		  
        .finally(() => this.loading = false)
    },




    /**
     * Получить информацию о дереве
     */
    load  ()   {
			this.loading  = true
			this.doSearch = false
			this.loadTree()
		},

		moveCenter () {
			this.treeEditor.stage.moveCenter(
				this.treeEditor.treeLayer.root.view
				)
		},
		reload () {
			this.treeEditor.stage.destroyChildren()
			this.load()
		},

		export() {
			this.exportText =  '';
			this.treeEditor.treeLayer.root.forAllChilden ( (node, level) => {

				let path  = "\t".repeat(level) + node.title
				let lines = []
				if (node.hasQueries() )	{
					lines = node.queries.map ( (q) => path + ': ' +q.title )
				} else {
					lines.push(path)
				}
				this.exportText += lines.join("\n")

			}, true )

			this.$refs['export-form'].show()
		},

		switchSearch() {
			this.doSearch = ! this.doSearch
		},


		keyBindings (event) {


			switch (event.which) {
				// "ESC"
				case 27:
				this.doSearch = false
				break;
				// Ctrl+F
				case 70:
				if (event.ctrlKey)  {
					event.stopPropagation()
					event.preventDefault()
					this.switchSearch();
				}
				break;

			}
		}


	}
	
}
</script>
