<template>
    <img :src="url" />
</template>

<script>
    export default {
        name: 'v-icon',
        props : {
            'size' : {'default':50},
            'type' : {'default':'ios'},
            'icon' : {
                type: String,
                required: true
            }
        },

        computed: {
            url: function ()  {
                    return  'https://img.icons8.com/'+this.type+'/'+this.size+'/000000/'+this.icon+'.png';
            }
        }
    }
</script>

