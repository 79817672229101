"use strict";

export default {
	state: {
	},
	namespaced: true,
	mutations: {

	},

	actions: {

	}
}