<template>
    <div class="content-wrapper kanban">
      <div class="user">
        Менеджер: <span class="name">{{user.name}}</span>(<span id="user_id">{{user.id}}</span>)
			  
      </div>

      <spinner v-if="showSpinner" />
      <template v-else>

        <kanban-board

            :stages="stages.map(e => e.guid)"
            :blocks="blocks"
            @update-block="updateBlock"
         >
          <div v-for="stage in stages" :slot="stage.guid" :key="stage.guid" class="stages">
              <div class="stage">			  
                {{ stage.name }} 
              </div>
          </div>
          <div v-for="block in blocks" :slot="block.id" :key="block.id">
			<a href="#" class="btn btn-tool" data-toggle="modal" data-target="#leadModal" >
				<div class="name">
					<div class="card-header">
						Заказ № {{ block.id }}
					</div>
					<div class="card-body">
						<div>Покупатель: <span class="order-param">{{ block.customer_name }}</span></div>
						<div>Адрес доставки:<span class="order-param address">{{ block.address }}</span></div>
					</div>
				</div>
			</a>

          </div>
        </kanban-board>
      </template>
   


		<!-- Modal -->
		<div class="modal fade" id="leadModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
			<div class="modal-content">
			  <div class="modal-header">
				<span class="block_id"></span>
				<h4 class="modal-title" id="myModalLabel"></h4>
				<button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
			  </div>
			  
			  <div class="modal-body">
			  
				  <div class="card card-primary card-tabs">
					<div class="card-header p-0 pt-1">
						<ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
							<li class="nav-item">
								<a class="nav-link active" id="custom-tabs-one-home-tab" data-toggle="pill" href="#custom-tabs-one-home" role="tab" aria-controls="custom-tabs-one-home" aria-selected="true">Контактные данные</a>							
							</li>
							<li class="nav-item">
								<a class="nav-link" id="custom-tabs-one-profile-tab" data-toggle="pill" href="#custom-tabs-one-profile" role="tab" aria-controls="custom-tabs-one-profile" aria-selected="false">Товары</a>
							</li>					
							
							<li class="nav-item">
								<a class="nav-link" id="custom-tabs-one-messages-tab" data-toggle="pill" href="#custom-tabs-one-messages" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Комментарии</a>
							</li>						
						</ul>
					</div>
					
					<div class="card-body">
						<div class="tab-content" id="custom-tabs-one-tabContent">
						  <div class="tab-pane fade show active" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
						  </div>
						  <div class="tab-pane fade" id="custom-tabs-one-profile" role="tabpanel" aria-labelledby="custom-tabs-one-profile-tab">
						  </div>
						  <div class="tab-pane fade" id="custom-tabs-one-messages" role="tabpanel" aria-labelledby="custom-tabs-one-messages-tab">
						  </div>
						</div>
					  </div>				
				  </div>
			  
			  </div>
			  
			  <div class="modal-footer">
				<button type="button" class="btn btn-secondary" v-on:click="setWinState">Win </button>
				<button type="button" class="btn btn-secondary" v-on:click="setLoseState">Lose </button>
				<button type="button" class="btn btn-primary" v-on:click="saveOrder">Сохранить</button>
			  </div>
			  
			</div>
		  </div>
		</div>	  
	
	
    </div>

	
	
</template>


<script>
import axios from  'axios'
import spinner from "./spinner";
const REFRESH_TIME = 1000 * 5 // 5 секунд


export default {
    name: "OrderPage",
    components:{spinner},
    data(){
      return {
        showSpinner: false,
        blocks: [],
        timer: null,
        stages: []
      }

    },

    mounted() {
	


		$('#leadModal').on('show.bs.modal', function (event) {
		
			$('#custom-tabs-one-home').html("");
			$('#custom-tabs-one-profile').html("")
			$('#custom-tabs-one-messages').html("")					
		
			var modal = $(this)
			
			modal.find('.modal-title').text('')
		
			var button = $(event.relatedTarget) 
			var id = button.parent().parent().attr('data-block-id')			
			
			axios.get(`/api/order/${id}`).then( response => {
			
				console.log("/api/order/ response:");		
				
				modal.find('.block_id').text(id);
				modal.find('.modal-title').text('Заказ № ' + response.data.order.id)
				
				
				var html = '<div class="row">'
				
				

				html += '<div class="col-sm-4 form-group param-name">'
				
					html += '<div class="form-group"><span>Покупатель</span></div>'
					html += '<div class="form-group"><span>Компания</span></div>'
					html += '<div class="form-group"><span>Адрес доставки</span></div>'

				html += '</div>'
				html += '<div class="col-sm-8 form-group param-value">'
				
					html += '<div class="form-group"><span>'+ response.data.customer.name +'</span></div>'
					html += '<div class="form-group"><span>'+ response.data.company.name +'</span></div>'
					html += '<div class="form-group"><input class="form-control" type="text" name="order_address" id="order_address" value="'+ response.data.order.address +'" /></div>'
				
				html += '</div></div>'
				
				
				$('#custom-tabs-one-home').html(html)
				
				html = '<div class="card-body p-0">'
				html += '<table class="table table-striped">'
				html += '<tr><th style="width:10%">Фото</th>'
				html += '<th style="width:50%">Название</th>'
				html += '<th style="width:10%">Кол-во</th>'
				html += '<th style="width:25%">Стоимость</th><th style="width:5%"></th></tr>'

				response.data.goods.forEach(function(product)
				{
					html += '<tr prod-id="'+product.id+'" ><td><img src="'+product.image+'" width="40px"/></td><td>'+product.name+'</td><td><input prod-id="'+product.id+'" name="quantity" type="number" value="'+ product.quantity +'"/></td><td><span id="t'+product.id+'">'+ product.price +'</span></td><td><li title="Удалить" name="basket"  class="basket fa fa-trash" onclick=" $(this).parent().parent().addClass(\'delete\')"></li></td></tr>'
				})
				
				html += '<tr><td colspan="3"></td><td colspan="2">Итого: <span id="total">' + response.data.total + '</span> руб.</td>'
				
				html += '</table></div>'
				$('#custom-tabs-one-profile').html(html)

			})
			
			axios.get(`/api/order_comments/${id}`).then( response => {

				var html = '';
				
				response.data.forEach(function(comment) {
					//console.log(comment);
					
					html += '<div class="comment-block"><div class="comment-header"><span class="date">'+comment.createdate+'</span><span class="user">'+comment.name+'</span></div><div class="comment">'+comment.comment+'</div></div>'
					
				})
				
				html += '<span>Комментарий:</span><div class="comment-bock-new"><textarea class="comment" id="comment_new"></textarea></div>';

				$('#custom-tabs-one-messages').html(html)					
				
			})
		})			
		
	
		this.loadStages()
        this.fetchBlocks(true)
		
        this.timer = window.setInterval(()=>{
          this.fetchBlocks()
        }, REFRESH_TIME)
		
    },

    beforeDestroy() {
      this.stopReload()
    },

    methods:{
		updateBlock(id, status) {
			this.blocks.find(b => b.id === Number(id)).status = status;
			this.putBlocks(id, status)
		},

		stopReload() {
			this.timer && window.clearInterval(this.timer)
		},


		loadStages() {
			axios.get('/api/order_statuses').then( response => {
			  this.stages = response.data
			})
		},


		/**
		* Загрузка блоков
		*/
		fetchBlocks(mute = false){

		// Отобразить спинер только при первоначальной загрузке
		mute && (this.showSpinner = true)

		axios.get('/api/orders').then( response => {
			  this.blocks = response.data
			}).finally(() => this.showSpinner = false)
		},


		putBlocks(id, status) {
			axios.put(`/api/order/${id}`, {status:status}).then( response => {})
		},

		setWinState: function (event) {
			var id = $('#leadModal').find('.block_id').text();
			axios.put(`/api/order/${id}`, {status:'win'}).then( response => {
				this.fetchBlocks();
				$('#comment_new').val("");
				$("#leadModal").modal('hide');
			})
			
		},

		setLoseState: function (event) {
			var id = $('#leadModal').find('.block_id').text();
			axios.put(`/api/order/${id}`, {status:'lose'}).then( response => {
				this.fetchBlocks();
				$('#comment_new').val("");
				$("#leadModal").modal('hide');			
			})
			
		},

		saveOrder() {
		
			var order_id = $('#leadModal').find('.block_id').text();
			var address = $('#order_address').val();
			var comment_new = $('#comment_new').val();
			if(comment_new != "")
			{
				var user_id = $("#user_id").text();
				axios.put(`/api/order_comments/${order_id}`, {comment:comment_new, order_id:order_id, user_id:user_id}).then( response => {		
					$('#comment_new').val("");
				})
			}

			$(".delete").each(function() {

				var good_id = $( this ).attr('prod-id');
				
				axios.put(`/api/order_good_delete`, {order_id:order_id, good_id:good_id}).then( response => {
				})
				
			});
			
			$("input[name='quantity']").each(function() {
			
				var good_id = $( this ).attr('prod-id');
				var quantity = $( this ).val();
				
				axios.put(`/api/order_goods_update`, {order_id:order_id, good_id:good_id, quantity:quantity}).then( response => {
				})
				
			});
			
			
			axios.put(`/api/order/${order_id}`, {address:address}).then( response => {

				$(".drag-container").find("[data-block-id="+order_id+"]").find(".address").html(address);
				$("#leadModal").modal('hide');
			
			})
		
		},
		
		
    },
    computed:{
        user() {
          return window.user
        }
    }
}
</script>
<style lang="scss">
  @import "../../sass/kanban.scss"; 
</style>



