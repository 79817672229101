"use strict";


import Stage  from './common/stage.js'
import Tree  from './common/tree.js'

//const DandelionTree  = require('./dandelion_tree/tree.js')
//const SimpleTree  = require('./simple_tree/tree.js')
import SimpleTree from './simple_tree/tree.js'

export default class  TreeEditor {

	constructor(option) {


		this.option = {
			onReady :  () => {},
		 	onChange : () => {},
		 	...option
		}

		if (! this.option.container) {
			console.warn("Не указан контейнер")
			return;
		}

		this.stage = new Stage({
			container: this.option.container,
			width    : this.option.width,
			height   : this.option.height
		})

		this.stage.on("ready", () => {
			this.option.onReady.call(this)
		})


		this.stage.on("change", (node) => this.option.onChange.call(this, node) )

	}

	clear()  {
		this.stage.destroy()
	}

	load(tree) {

		this.treeLayer = new SimpleTree()

		this.stage.add(this.treeLayer)

		this.treeLayer.load(tree)

		//json.stage && this.stage.setAttrs(json.stage)

		return this

	}

	serialize() {
		return  {
			'data' : this.treeLayer.serialize(),
			'stage':{
				position: this.stage.position(),
				scale: {
					x: this.stage.scaleX(),
				 	y: this.stage.scaleX()
				},
			}
		}
	}


}
