"use strict";
const Konva     = require('konva')
const TextTable  = require ('../widget/table.js')

const STYLESHEET = {
  'fill' :   {
	fontSize :12,
	fill     :'#333333',
	padding:10,
  },


}

module.exports = class InfoTable  extends  Konva.Group  {

	constructor(param) {
		super()

		let dataTable  = param.map((q) => {
		   return  [q.title ].concat(q.data)
		})

		let option = {
			common: {padding:10, rowHeight: 15},
			cols: [{}, {align:'right'}],
			id: 'table'
		}

		let table =  new TextTable(dataTable, option)
		let sub = new Konva.Rect({
			width: table.getClientRect().width,
			height: table.getClientRect().height,
			/*width: 400,
			height: 200,*/
			fill: '#fcffc1aa',
			stroke: "#aaa",
			strokeWidth: 1,
			cornerRadius:3,
			/*"shadowEnabled": true,
			"shadowColor": "grey",
			"shadowEnabled": true,
			"shadowBlur": 5 */
		}).cache()

		this.add(sub,table)

	}

	width() {
		return this.getClientRect({skipShadow:true}).width
	}

	height() {
		return this.getClientRect({skipShadow:true}).height
	}

	selectRow(row) {
		this.findOne('#table').find(`.row_${row}`).setAttrs({fill:'red'})
		return this.findOne('#table').findOne(`.row_${row}`)
	}

	removeSelect() {
		this.findOne('#table').find('.cell').setAttrs({fill:'black'})
		return this
	}


}
