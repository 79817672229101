<template>

	<div
	v-bind:class="{ 'build-page' : loading }"
	class='info position-absolute b-1  h-100  border border-dark'>
	<div class='card' v-if='model' >

		<header  class="modal-header">
			<h5 class="card-title">Название карточки</h5>
			<button @click='hide' type="button" aria-label="Close" class="close">×</button>
		</header>

		<div class="card-body">

			<ul class="list-group list-group-flush">
				<li class="list-group-item"><span class='label'>Создано</span><span>653</span></li>
				<li class="list-group-item"><span class='label'>Обновлено</span><span>654</span></li>
				<li class="list-group-item"><span class='label'>Владелец</span><span>654</span></li>
				<li class="list-group-item"><span class='label'>Кластеров</span><span>654</span></li>
			</ul>
			<h6>Доступно:</h6>
		</div>
	</div>
</div>
</template>

<script>
	export default {
		name: 'v-info',
		props:{
			model:null
		},
		data () {
			return {
				loading:false
			}
		},
		methods: {
			hide() {

			}
		}
	}
</script>

<style scoped >
.info {
	width:20%;
	top:0;
	right:0;
	left:auto;
	box-shadow: 0 0 5px #666;
}
</style>