<template >
	<div class='search p-2 bg-dark ' >
		<div class='form-group'>
			<div class="input-group">
				<div class="input-group-prepend">
					<div class="input-group-text"><v-icon icon='search' class="s20"></v-icon></div>
				</div>
				<input type="text"  v-model='query' ref='search'
					v-on:keyup.stop.prevent='keyBindings'
					v-on:keyup.enter='doRepeat'
					v-on:change='doSearch'
					width='550'
				 class="form-control"  placeholder="строка запроса"></input>
			</div>
			<div v-if='found.length > 0'>
				<button class='btn' v-on:click="doPrev" v-if='found.length > 1'><v-icon icon='left' class="s20"></v-icon></button>
				<span v-if='found.length > 1'>{{current+1}} / {{found.length}}</span>
				<button class='btn'  v-on:click="doNext" v-if='found.length > 1'><v-icon icon='right' class="s20"></v-icon></button>
			</div>
		</div>
	</div>
</template>
<script>
const  TIME_DELAY = 1000
export default {
	name: 'search-form',
	treeEditor: null,
	_currentNode : null,
	timer: null,
	data() {
		return {
			query: '',
			current: null,
			found: [],
		}
	},
	created() {
		this.$nextTick(() =>this.$refs.search.focus())
	},
	destroyed() {
		this.found.forEach (item => {
			item.node.removeStyle('selected')
		})
	},
	methods: {


		show(index) {

			let searchObject = this.found[this.current]
			let node = searchObject.node
			let row = searchObject.row
			let foundObject

			if (this._currentNode != node) {

				// убрать попап у прежде выделленого
				if (this._currentNode != null  ) {
					this._currentNode.removeStyle('selected').removeStyle('red').destroyInfo()
				}

				// Раскрыть все ветки до текущей
				searchObject.node.allParents().reverse().forEach( (parent)  => {
					this.$parent.treeEditor.treeLayer.expand(parent, false)
				})

				this._currentNode = node
				this._currentNode.addStyle('selected').showInfo()


			}
			if (row==-1) {
				this._currentNode.destroyInfo()
				this._currentNode.addStyle('red')
				foundObject =  this._currentNode.view
			} else {

				this._currentNode.removeStyle('red')
				let info  = this._currentNode.view.findOne('#info')
				foundObject = info ?
								info.removeSelect().selectRow(row) :
								this._currentNode.addStyle('selected').showInfo().selectRow(row)

			}

			this.$parent.treeEditor.stage.moveCenter(foundObject)

		},


		doNext() {

			this.current++;
			if (this.current > this.found.length -1 )  {
				this.current = 0;
			}
			this.show()

		},

		doRepeat() {
			if (this.found.length > 0 )  {
				this.doNext()
			}
		},

		doPrev() {
			this.current--;
			if (this.current < 0)  {
				this.current = this.found.length-1;
			}
			this.show()
		},



		createFilter() {

			let tokens = this.query.trim().split(" ").map(s => s.trim().toUpperCase() ).filter(s => s.length)

			return   (str)  => {
				return  tokens.every( token => str.toUpperCase().indexOf(token) !==-1 )
			}
		},

		checkSearch (str) {
			return str.indexOf( this.query.trim() ) !== -1
		},

		doSearch (event) {


			let filter  = this.createFilter()


			this.found = []
			this.$parent.treeEditor.treeLayer.root.forAllChilden( (node) => {

				if ( node.isVisible() ) {
					 node.destroyInfo()
				}

				node.queries.forEach( (q, row) => {
					if (  filter(q.title) )   {
						this.found.push({node:node, row:row})
					}
				})

				if ( filter(node.title) )  {
					this.found.push({node:node, row:-1})
				}

			})

			this.current = -1

		},

		keyBindings (event){

			if (event.which == 27 ) {
				this.$root.$emit('search')
			}

		}
	}
}
</script>
<style>
	.search {
		position:absolute;
		bottom:0;
		left:0;
		width:30%;
	}
	.search .form-group {
		margin-bottom: 0;
	}
</style>