"use strict";
const Konva = require('konva')
const _     = require ('lodash')

const TEXT_STYLE = {
		"padding"    :15 ,
		"fontFamily" :"Arial" ,
		"fontSize"   :14,
		'align':'left'
}

module.exports = class Table   extends   Konva.Group  {

	constructor(tableData, options) {
		super(options)

		let dim_col  = []
		let dim_row =  []
		let rows = []

		tableData.forEach((row, row_id ) =>  {
			let cols = []
			row.forEach((string, col_id ) => {

				let opt = _.defaultsDeep(
					{},
					_.get(options,`rows[${row_id}]`, {}),
					_.get(options,`cols[${col_id}]`, {}),
					_.get(options,'common', {}),
					TEXT_STYLE
				)

				/*if (row_id === options.sel ) {
					opt['fontStyle'] = 'bold';
					opt['fill'] = 'red'
					id['id']= 'row_select'
				}*/

				let text =  new Konva.Text({text:string, ...opt })
				dim_col[col_id] = dim_col[col_id] ?  Math.max(dim_col[col_id] , text.width() ) : text.width()
				dim_row[row_id] = dim_row[row_id] ?  Math.max(dim_row[row_id] , text.height() ) : text.height()
				text.addName(`row_${row_id}`)
				text.addName(`col_${col_id}`)
				text.addName(`cell`)
				cols.push(text)

			})
			rows.push(cols)
		})

		const  offsetX = (i) => {
			return i == 0 ? 0  : _.sum(dim_col.slice(0, i))
		}

		const  offsetY = (i) => {
			return i * 20
		}

		rows.forEach( (row, row_id) => {
			row.forEach( (text, col_id ) => {
				let cell = new Konva.Group({x: offsetX(col_id), y: offsetY(row_id)})
				text.position({x: offsetX(col_id), y: offsetY(row_id)}).width(dim_col[col_id])
				this.add(text)
			})
		})
	}


}
