var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-nav",
    [
      _c("b-button-group", [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-dark",
            attrs: { title: "К списку" },
            on: {
              click: function($event) {
                return _vm.$router.push({ path: "/" })
              }
            }
          },
          [_c("v-icon", { attrs: { icon: "category" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-dark",
            attrs: { title: "Перегрузить" },
            on: {
              click: function($event) {
                return _vm.$root.$emit("reload")
              }
            }
          },
          [_c("v-icon", { attrs: { icon: "synchronize" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("b-button-group", { staticClass: "ml-2" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-dark",
            attrs: { title: "Поиск" },
            on: {
              click: function($event) {
                return _vm.$root.$emit("search")
              }
            }
          },
          [_c("v-icon", { attrs: { icon: "search" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("b-button-group", { staticClass: "ml-2" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-dark",
            attrs: { title: "Экспорт" },
            on: {
              click: function($event) {
                return _vm.$root.$emit("export")
              }
            }
          },
          [_c("v-icon", { attrs: { icon: "export" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "navbar-brand" }, [_vm._v(_vm._s(_vm.title))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }