<template>
	<div class="card m-1">
		<div class="card-body"
		v-bind:class="{ 'bg-secondary' : drag }"
		>
			<div class='text-center ' v-if = "upload">
				<b-spinner  style="width: 3rem; height: 3rem;" ></b-spinner>
				<div>{{percent}}</div>
			</div>
			<label
				v-on:dragover.prevent='drag = true'
				v-on:dragleave='drag = false'
				v-if = "! upload"
				v-on:drop='drop' >
				<div class='text-center '>
					<v-icon  icon='upload' size='50' ></v-icon>
					<div>кликните,  или перетащите сюда файл </div>
					<input v-if=" !drag " type='file' accept='.xlsx' v-on:change='change' >
				</div>
			</label>

		</div>
	</div>
</template>

<script>

	export default {
		name: 'upload',

		data() {
			return {
				upload :false,
				drag   :false,
				percent:0
			}
		},
		methods: {

			uploadFile(file) {
				const  data = new FormData();
				data.append("file", file)
				this.upload = true
				this.percent = 0
				this.$store.dispatch('dashboard/upload',[data,this.progressHandler])
				.finally( () => this.upload =false)

			},

			drop(event) {
				event.stopPropagation()
				event.preventDefault()
				this.drag = false
				this.uploadFile(  event.dataTransfer.files[0] )
			},

			change (event) {
				let files = event.target.files || event.dataTransfer.files;
				files && this.uploadFile(  files[0] )
			},

			progressHandler(event) {
				this.percent = Math.round((event.loaded / event.total) * 100)
			}
		}
	}
</script>
<style scoped>
input[type='file'] {
	display: none;
}
label {
	width:100%;
	cursor: pointer;
}
.card {
	width:25%;
}

</style>
