<template>
	<div class="card m-1 tile" :class="{ editable: model.editable}" >

		<div class="card-body" v-on:dblclick='edit'>

			<div class='position-absolute controls edit-button' v-if ='self'>
				<b-button
					v-show="! editabled"
					v-on:click='edit'
					size="sm"
					variant="light"
					title = 'Редактировать'
				>
					<v-icon icon="edit"/>
				</b-button>

				<b-button
				v-show="editabled"
				v-on:click='save'
				size="sm"
				variant="outline-primary"
				title = 'Сохранить'
				>
					<v-icon icon="save"/>
				</b-button>
			</div>

			<v-icon  v-if="! self" icon='share' class='share-i  p-1'></v-icon>
			<v-icon  v-if="self && model.shared" icon='user'  class='share-i  p-1 s20' title='Доступно другим пользователям '></v-icon>

			<h5 class="card-title">

				<div  v-show="! editabled" v-on:click='editor'>{{ model.name }}</div>
				<b-input
				v-show="editabled"
				type  ="text"
				v-model="model.name"
				@keyup.esc="cancel"
				:maxlength="maxTitle"
				/b-input>

			</h5>
			<p class="card-text" v-if="! editabled">{{model.description}}</p>
			<b-textarea v-show="editabled" v-model="model.description" @keyup.esc="cancel"></b-textarea>

			<div class='position-absolute small bg-light w-100 info-panel b-1' v-if="! editabled">
				<span class='p-2'>
					Создано:
					{{ created }}
				</span>

				<span class='m-2'>
					Кластеров:
					{{ model.meta.clusters }}
				</span>

				<span class='m-2' v-show="model.meta.request">
					Запросов:
					{{ model.meta.request }}
				</span>
			</div>

			<div class='controls action-buttons position-absolute text-center '  v-if="! editabled">

				<b-button
				size="sm"
				variant="light"
				v-on:click='tree'
				title = 'Дерево'>
					<v-icon icon="active-directory"/>
				</b-button>

				<!--
				<b-button
				size="sm"
				variant="light"
				v-on:click='editor'
				title = 'Редактировать'>
					<v-icon icon="active-directory"/>
				</b-button>

				<b-button
				size="sm"
				variant="light"
				v-on:click='copy'
				title = 'Копировать'>
					<v-icon icon="copy"/>
				</b-button>
			   -->
				<b-button
				v-if ='self'
				size="sm"
				variant="light"
				v-on:click='share'
				title = 'Поделиться'>
					<v-icon icon="share"/>
				</b-button>

				<b-button
				size="sm"
				variant="light"
				v-on:click='info'
				title = 'Подробнее'>
					<v-icon icon="info"/>
				</b-button>

				<b-button
				size="sm"
				variant="light"
				v-on:click='remove'
				title = 'Удалить'>
					<v-icon icon="delete"/>
				</b-button>
			</div> <!-- control -->
		</div> <!-- card-body -->
	</div> <!-- card -->
</template>

<script>

	import moment from 'moment'
	import 'moment/locale/ru'
	moment.locale('ru')

	export default {
		name: 'tile',
		props: ['model'],
		data() {
			return {
				maxTitle: 50,
				editabled: false,
				cached: {},
			}
		},
		created() {
			this.$root.$on('keyup.esc', () => this.cancel() )

		},

		computed: {
			self () {
				return this.$store.state.user.id == this.model.owner.id
			} ,
			created() {
				return moment(this.model.created_at, "X").format('LL')
			},
			updated() {
				return moment(this.model.updated_at, "X").format('LL')
			}
		},

		methods: {
			save () {
				this.$store.dispatch('dashboard/save',this.model)
				.then( response  =>   {
					this.editabled  = false
					this.$root.$emit('notice', 'Запись сохранена')
				})
			},

			cancel () {
				if (this.editabled) {
					Object.assign(this.model,this.cached)
					this.editabled  = false
				}
			},

			edit ()  {
				if ( this.self ) {
					this.$root.$emit('keyup.esc')
					Object.assign(this.cached,this.model)
					this.editabled  = true
				}
			},

			copy ()  {
				this.$store.dispatch('dashboard/copy',this.model)
				.then( response  =>   {
					this.$root.$emit('notice', 'Создана новая запись')
				})
			},

			share() {
				this.$parent.$refs['share-form'].show()
				.then( (email) => {
					this.$store.dispatch('dashboard/share',[this.model,email])
					.then(response  => {
						this.model.shared = true
						this.$root.$emit('notice', 'Данные теперь доступны для пользователя')
					})
				})
			},

			remove ()  {
				this.$bvModal.msgBoxConfirm('Действительно хотите удалить?')
				.then( confirm => {
					if (confirm) {
						this.$store.dispatch('dashboard/remove',this.model)
						.then( response  => {
							this.$root.$emit('notice', 'Запись удалена')
						})
					}
				})
			},

			editor () {
				this.$router.push({name:'tree', params:{id:this.model.id}} )
			},

			tree () {
				this.$router.push({name:'tree', params:{id:this.model.id}} )
			},

			info () {
				alert('TODO')
			}
		}
	}
</script>

<style scoped >
.tile {
	width:25%;
}
.info-panel,
.action-buttons  {
	bottom:0.5em;
	margin: 0 auto;
	left: 0;
	right: 0;
}
.edit-button {
	right:0;
	top:0;
}
.controls {
	opacity:0;
	transition: opacity   100ms;
}
.share-i {
	position:absolute;
	top:2px;
	left:2px;
	background: rgba(0,0,0,.2);

	border-radius: 5px;
}
.tile:hover {
	box-shadow: 0 0 5px #666;
}
.tile:hover >>> .controls {
	opacity:1;
}
.tile:hover >>> .info-panel {
	transition: opacity   100ms;
	opacity:0;
}
.card-title >>> div {
	cursor:pointer;
}

</style>