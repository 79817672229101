<template>
	<div class="p-1  h-100  position-relative area"
	  v-bind:class="{ 'build-page' : loading }"
	  @click.self=" $root.$emit('keyup.esc')">

		<div class='d-flex flex-wrap  align-content-start'>

				<tile
					v-for="model in $store.state.dashboard.list"
					v-bind:model="model"
					v-bind:key="model.id"
					v-if ='$store.getters.isLoggedIn'
				>
				</tile>

			<upload v-if ='! loading'  ></upload>
		</div>
		<!--<info></info>-->
		<share-form ref="share-form"></share-form>
	</div>
</template>


<script>

import tile  from './tile.vue'
import share from './share-form.vue'
import tools  from './nav-tool.vue'
import info  from './info.vue'
import upload  from './upload.vue'

export default {

	name: 'dashboard',

	computed: {

	},

	data () {
		return {
			loading: false,
		}
	},

	mounted() {
		window.addEventListener("keyup", this.onEscapeKeyUp.bind(this));
		this.$store.commit('submenu',tools)
		this.$root.$on('dashboard.reload',this.load )
		this.load()
	},
	beforeDestroy() {
		window.removeEventListener("keyup", this.onEscapeKeyUp.bind(this))
		this.$store.commit('submenu',null)
		this.$root.$off('dashboard.reload')
	},

	components: {
		'tile': tile,
		'share-form': share,
		'upload': upload,
		'info' : info
	},

	methods: {
		load() {
			//this.loading  = true
			//this.$store.dispatch('dashboard/loadtree')
			//.finally(() => this.loading = false )
		},

		onEscapeKeyUp( event ) {
			event.which === 27 && this.$root.$emit('keyup.esc')
		}
	}
}

</script>
<style scoped>
	.area {
		overflow: hidden;
	}
</style>



