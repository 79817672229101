<template>
	<div  class="node-info"  >
		<div class='header'>
			{{model.name}}
		</div>
		<!--
		<div class='path section'>
			<label>Путь:</label>
			<ul>
				<li v-for="parent in path" :key="parent.id" >
					{{parent.name}}
				</li>
			</ul>
		</div>
	-->
		<div class='data section' v-bind:class="{ 'build-page':loading }">
			<div v-if="!loading && model.data" class='data-table'>
				<table class='table table-sm'>
					 <thead class="thead-light">
						<tr>
							<th>Фраза</th>
							<th>Базовая частотность<br/></th>
							<th>Частотность " "</th>
							<th>Частотность "!"</th>
						</tr>
					</thead>
					<tr v-for="data in model.data.data"  >
						<td>{{data.title}}</td>
						<td>{{data.data[0]}}</td>
						<td>{{data.data[1]}}</td>
						<td>{{data.data[2]}}</td>
					</tr>
				</table>
			</div>
		</div>
		<div class='comment'>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'info',
		props: {
			model: {
				type: Object
			},
		},
		data() {
			return {
				loading:false

			}
		},
		computed: {
			path() {
				var node =  this.model
				var path = []
				while ( node.parent !== null   ) {
					node = node.parent
					path.push(node)
				}
				console.log(path)
				return path.reverse()
			},
		},
		methods: {
			load(){
				this.loading = true
				this.$http.get('/seo/node/data/' + this.model.id )
				.then(response =>  {
					this.loading = false
					this.model.data = response.data
					//console.log(this.model.data)
				})
			}
		}
	}
</script>

<style scoped lang="scss" >
	.node-info {
		height:100%;
		min-width:20vw;
	}
	.section label {
		margin-top:0.5em;
	}
	.data {
		height:100%;
	}
	.path ul {
		margin:0;
		padding:0;
	}
	.path li {
		margin:0;
		padding:0;
		list-style: none;
		display: inline;
		color:#0052ff;
	}
	.path li:before  {
		content:">";
		color:#000;
	}
	.path li:first-child:before {
		content:"";
	}

	.node-info .header {
		font-size:1.2em;
		font-weight: 600;
		background-color: rgba(#0052ff,0.1);
		padding-left:1em;
	}
	.node-info .path {

	}
	.node-info .section {
		border-top:1px solid #343a40;
	}
	.data-table {
		overflow-y: scroll;
		max-height: 80%;
	}
</style>
