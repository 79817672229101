var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vtl" }, [
    _vm.model.pid !== 0
      ? _c("div", [
          _c("div", {
            staticClass: "vtl-border vtl-up",
            class: { "vtl-active": _vm.isDragEnterUp },
            on: {
              drop: _vm.dropBefore,
              dragenter: _vm.dragEnterUp,
              dragover: _vm.dragOverUp,
              dragleave: _vm.dragLeaveUp
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: _vm.treeNodeClass,
              attrs: {
                id: "node_" + _vm.model.id,
                draggable: !_vm.model.dragDisabled
              },
              on: {
                dragstart: _vm.dragStart,
                dragover: _vm.dragOver,
                dragenter: _vm.dragEnter,
                dragleave: _vm.dragLeave,
                drop: _vm.drop,
                dragend: _vm.dragEnd,
                mouseover: _vm.mouseOver,
                mouseout: _vm.mouseOut,
                dblclick: function($event) {
                  $event.stopPropagation()
                  return _vm.click($event)
                }
              }
            },
            [
              _vm.model.isLast && !_vm.model.isLast()
                ? _c(
                    "span",
                    {
                      staticClass: "vtl-caret vtl-is-small",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.toggle($event)
                        }
                      }
                    },
                    [
                      _c("v-icon", {
                        staticClass: "s20",
                        attrs: { icon: _vm.caretClass }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.editable
                ? _c("div", { staticClass: "vtl-node-content" }, [
                    _vm._v("\n\t\t\t" + _vm._s(_vm.model.name) + "\n\t\t")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editable
                ? _c("input", {
                    ref: "nodeInput",
                    staticClass: "vtl-input",
                    attrs: { type: "text" },
                    domProps: { value: _vm.model.name },
                    on: {
                      keyup: [
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.updateName($event)
                        },
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "esc", 27, $event.key, [
                              "Esc",
                              "Escape"
                            ])
                          ) {
                            return null
                          }
                          return _vm.setUnEditable($event)
                        }
                      ],
                      blur: _vm.setUnEditable
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHover,
                      expression: "isHover"
                    }
                  ],
                  staticClass: "vtl-operation"
                },
                [
                  _c(
                    "span",
                    {
                      attrs: { title: "Информация" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.infoNode($event)
                        }
                      }
                    },
                    [
                      _vm._t("infoNode", [
                        _c("v-icon", {
                          staticClass: "s20",
                          attrs: { icon: "info" }
                        })
                      ])
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.model.editNodeDisabled
                    ? _c(
                        "span",
                        {
                          attrs: { title: "Редактировать" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.setEditable($event)
                            }
                          }
                        },
                        [
                          _vm._t("editNode", [
                            _c("v-icon", {
                              staticClass: "s20",
                              attrs: { icon: "edit" }
                            })
                          ])
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.model.delNodeDisabled
                    ? _c(
                        "span",
                        {
                          attrs: { title: "Удалить" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.delNode($event)
                            }
                          }
                        },
                        [
                          _vm._t("delNode", [
                            _c("v-icon", {
                              staticClass: "s20",
                              attrs: { icon: "trash" }
                            })
                          ])
                        ],
                        2
                      )
                    : _vm._e()
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm.model.children && _vm.model.children.length > 0 && _vm.expanded
            ? _c("div", {
                staticClass: "vtl-border vtl-bottom",
                class: { "vtl-active": _vm.isDragEnterBottom },
                on: {
                  drop: _vm.dropAfter,
                  dragenter: _vm.dragEnterBottom,
                  dragover: _vm.dragOverBottom,
                  dragleave: _vm.dragLeaveBottom
                }
              })
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isFolder
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.model.pid === 0 || _vm.expanded,
                expression: "model.pid === 0 || expanded"
              }
            ],
            class: { "vtl-tree-margin": _vm.model.name !== "root" }
          },
          _vm._l(_vm.model.children, function(model) {
            return _c(
              "item",
              {
                key: model.id,
                attrs: {
                  "default-tree-node-name": _vm.defaultTreeNodeName,
                  "default-leaf-node-name": _vm.defaultLeafNodeName,
                  "default-expanded": _vm.defaultExpanded,
                  model: model
                }
              },
              [
                _vm._t("addTreeNode", null, { slot: "addTreeNode" }),
                _vm._v(" "),
                _vm._t("addLeafNode", null, { slot: "addLeafNode" }),
                _vm._v(" "),
                _vm._t("editNode", null, { slot: "editNode" }),
                _vm._v(" "),
                _vm._t("delNode", null, { slot: "delNode" })
              ],
              2
            )
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }