<template>
	<div  class="info-panel" v-bind:class="{ 'show' : isshow }" >
		<div class='header'>
			<button  class='btn btn-sm btn-outline-dark' @click="toggle" >
				<v-icon icon='close-pane'  class="s20" />
			</button>
		</div>
		<div class='content'>
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'infopanel',
		data() {
			return {
				isshow:false
			}
		},
		methods:{
			toggle ()  {
				this.isshow = !this.isshow
			},
			hide() {
				this.isshow = false
			},
			show() {
				this.isshow = true
			}
		}
	}
</script>

<style scoped>
.info-panel {

	position:fixed;
	background:#fff;
	top:55px;
	height:100%;
	right:0;
	transition:all 500ms;
	z-index:100;
	border-left:1px solid #343a40;
	transform:translateX(100%);
	box-shadow: -5px 0 5px rgba(0,0,0,0.5);
}
.info-panel .header {
	position:relative;
}
.info-panel .header  button{
	position:relative;
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	border-right:none;
	transform:translateX(-100%);
	background-color: #fff;
	z-index:101;
	box-shadow: -5px 0 5px rgba(0,0,0,0.5);
}
.info-panel  .header  button img  {
	transform: scaleX(-1);

}
.info-panel .content {
	padding:1em 2em 1em 1em;
	height:100%;
}
.info-panel.show {
	transform:translateX(0);
}
.info-panel.show  .header  button img  {
	transform:translateX(0)
}
</style>