var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-nav",
    [
      _c("b-button-group", [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-dark",
            attrs: { title: "Перегрузить" },
            on: {
              click: function($event) {
                return _vm.$root.$emit("dashboard.reload")
              }
            }
          },
          [_c("v-icon", { attrs: { icon: "synchronize" } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }