<template>
	<div id="tree" v-bind:class="{ 'build-page' : loading }" >
			<!--
			<tree v-bind:model="model"  v-if="root" ></tree>
		-->
		<vue-tree-list
			@click="onClick"
			@toggle="onToggle"
			@change-name="onChangeName"
			@drop="onDrop"
			@info="loadInfo"
			@delete-node="onDelete"
			:model="tree"
			default-tree-node-name="new node"
			default-leaf-node-name="new leaf"
			ref='tree'
			>
		</vue-tree-list>

	<infopanel v-if='infoModel'  ref='infopanel' >
		<info :model="infoModel" ref='info' />
	</infopanel>
	<search v-if='doSearch' ref='search-form'
		@search-run="searchRun"
		@search-next="searchNext"
		@search-prev="searchPrev"
	/>

	<b-modal title="Экспорт" ref="export-form" v-b-modal.modal-xl>
		<b-form-group>
			<b-form-textarea v-model="exportText" rows='15'  wrap="off">
			</b-form-textarea>
		</b-form-group>
	</b-modal>

</div>
</template>

<script>
//import tree  from './tree.vue'

import tools  from './nav-tool.vue'
import VueTreeList  from './VueTreeList.vue'
import infopanel  from './infopanel.vue'
import {Tree}  from './Tree.js'
import info  from './info.vue'
import search  from './search.vue'
import { addHandler, removeHandler } from './tools.js'
const  fileDownload = require('./file-download.js')

export default {
	name: 'tree-page',

	components: {
		//'tree': tree
		'VueTreeList': VueTreeList,
		'infopanel':infopanel,
		'info':info,
		'search': search,
	},

	data() {
		return {
			id : this.$route.params.id,
			tree: {},
			loading:false,
			infoModel:false,
			exportText: '',
			doSearch: false,
			title : ""

		}
	},

	beforeDestroy() {
		this.$store.commit('submenu',null)
		this.$root.$off('loadInfo',this.loadInfo)
		this.$root.$off('reload', this.load)
		this.$root.$off('search', this.switchSearch)
		this.$root.$off('export', this.export )
		removeHandler(window, 'keyup')
	},

	mounted() {


		this.$store.commit('submenu',tools)
		this.$root.$on('loadInfo',this.loadInfo)
		this.$root.$on('reload', this.load)
		this.$root.$on('search', this.switchSearch)
		//this.$root.$on('export', this.export )

		this.$root.$on('test', this.test )

		addHandler(window, 'keydown', (e) => {

			switch (e.keyCode) {
				case 27:
				this.doSearch = false
				break;
				case 70:
				if (e.ctrlKey)  {
					e.stopPropagation()
					e.preventDefault()
					this.switchSearch();
				}
				break;
			}
		})

		this.load()
	},

	methods: {

		loadInfo(node) {
			if (this.infoModel) this.infoModel.active = false
				this.infoModel = node.model
			this.$nextTick(() => {
				this.$refs['infopanel'].show()
				this.$refs['info'].load()
				node.model.active = true
			})
		},

		onClick(node) {
			if (node.isFolder) {
				node.toggle();
			} else {
				this.loadInfo(node)
			}
		},

		switchSearch() {
			this.doSearch = ! this.doSearch
		},


		_IOerror(error) {
			let message =  error.response.status && error.response.status == 403 ? 'Досутп к объекту запрещен' : 'Ошибка загрузки данных :' + error.message
			this.$root.$emit('error',message)
			this.loading = false
		},

		_nodeUpdate(id, data)  {
			//return this.$http.patch( '/seo/node/' + id +'?fields=id,'+Object.keys(data).join(','), data )
			return this.$http.patch( '/seo/node/update/' + id, data )
		},

		onToggle({expanded, id}) {
			this._nodeUpdate(id, {expand: expanded ? 1 : 0})
		},

		onChangeName({name, id}) {
			this._nodeUpdate(id, {title: name})
		},

		onDrop({target, node}) {
			this._nodeUpdate(node.id, {parent_id: target.id})
		},

		onDelete(node) {
			this._nodeUpdate(node.id, {deleted: node.deleted = node.deleted ? 1 : 0 })
		},


		load ()   {

			this.tree = {}
			this.loading  = true
			
			this.$http.post( '/seo/nodes')
			.then(response =>  {
			
				this.title  = ""
				this.$root.$emit('set-title', this.title )
				this.loading = false
				
				this.tree = new Tree(response.data)
			})			
		},

		export() {
			this.$http.get('/tree/' +  this.id +'/export' )
			.then(response =>  {
				this.exportText = response.data.text
				fileDownload(this.exportText,this.title+'.txt')
			})
		},
		test()
		{
			let id =  2198
			let node  = this.tree.getById(id)
			if (node)  {
				node.allParents().forEach( node => node.expanded = true )
				this.$refs['tree'].find('node_2198')
			}

		},
		// Поиск
		searchRun(string) {

			//this.$http.get('/tree/' +  this.id +'/export' )
			this.$refs.tree;
		},
		searchNext() {

		},
		searchPrev() {

		}


	}
}
</script>
<style>
#tree {
	padding:1em;
	height:100%;
}
</style>