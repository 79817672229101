"use strict";
const _  = require ('lodash')

class TreeNode    {

	constructor(data) {
		const { id, isLeaf } = data
		this.id =  data.id
		this.parent = null
		this.active = false
		this.children = []
		this.expanded = data.expanded
		this.deleted = data.deleted
		this.pid = data.pid
		this.isLeaf = !!isLeaf
		this.name 	= data.title
		this.hasdata = data.hasdata
	}

	isLast() {
		return  this.children.length == 0
	}

	changeName (name)  {
		this.name = name
	}

	addChildren (children) {
		if (!this.children) {
			this.children = []
		}

		if (Array.isArray(children)) {
			for (let i = 0, len = children.length; i < len; i++) {
				const child = children[i]
				child.parent = this
				child.pid = this.id
			}
			this.children.concat(children)
		} else {
			const child = children
			child.parent = this
			child.pid = this.id
			this.children.push(child)
		}
	}

	remove () {
		const parent = this.parent
		const index = parent.findChildIndex(this)
		parent.children.splice(index, 1)
	}

	_removeChild (child) {
		for (var i = 0, len = this.children.length; i < len; i++) {
			if (this.children[i] === child) {
				this.children.splice(i, 1)
				break
			}
		}
	}

	isTargetChild (target) {
		let parent = target.parent
		while (parent) {
			if (parent === this) {
				return true
			}
			parent = parent.parent
		}
		return false
	}

	moveInto (target) {
		if (this.pid === 0 || this === target) {
			return
		}

		if (this.isTargetChild(target)) {
			return
		}


		if (target.isLeaf) {
			return
		}

		this.parent._removeChild(this)
		this.parent = target
		this.pid = target.id
		if (!target.children) {
			target.children = []
		}
		target.children.unshift(this)
	}


	findChildIndex (child) {
		var index
		for (let i = 0, len = this.children.length; i < len; i++) {
			if (this.children[i] === child) {
				index = i
				break
			}
		}
		return index
	}

	_beforeInsert (target) {
		if (this.pid === 0 || this === target) {
			return false
		}


		if (this.isTargetChild(target)) {
			return false
		}

		this.parent._removeChild(this)
		this.parent = target.parent
		this.pid = target.parent.id
		return true
	}

	insertBefore (target) {
		if (!this._beforeInsert(target)) return

			const pos = target.parent.findChildIndex(target)
		target.parent.children.splice(pos, 0, this)
	}

	insertAfter (target) {
		if (!this._beforeInsert(target)) return

			const pos = target.parent.findChildIndex(target)
		target.parent.children.splice(pos + 1, 0, this)
	}

	isRoot()
	{
		return ! this.parent
	}


	static _forAllChilden(node,callback, filter, level = 1 ) {
		node.children.filter(filter).forEach(
			(node) => {
				callback.apply( node, [node , level] )
				this._forAllChilden(node,callback,filter, level+1 )
			}
		)
	}

	/**
	* Выполнить для всех потомков
	* @param  Node   node    корнева ветка
	* @param  {Function} callback [description]
	* @param  {Function}  filter   [description]
	* @return null
	*/
	forAllChilden(callback, self = false, filter = () => true) {
		self &&  callback.apply(this, [this, 0 ] )
		this.constructor._forAllChilden(this,callback, filter)
	}

	/**
	* Вернуть всех потомков
	*/
	allCildren(self, filter = () => true) {
		let childs = []
		this.forAllChilden(
			(node) =>  childs.push(node),self,filter)
		return childs
	}

	/**
	* Проверить является ли узел потомком
	* @param  Node  node проверяемый предок
	* @return {Boolean}      [description]
	*/
	isChild(node) {
		return this.allParents().includes(node)
	}

	/**
	* Вернуть всех родителей
	* @param  Node   node    корнева ветка
	* @param  {Function} callback [description]
	* @param  {Function}  filter   [description]
	* @return null
	*/

	allParents(self = false) {
		let parents = self ? [this] : []
		let current  = this

		while ( ! current.isRoot() ) {
			current = current.parent
			parents.push(current)
		}

		return parents
	}


	forAllView(callback, self = true, filter = () => true) {

		this.forAllChilden(
			(node) => callback.apply(node.view, [this] ) ,
			self,
			filter
			)
	}

	getById(id) {
		let find_node = false
		this.forAllChilden((node)=>{
			if (node.id == id ) {
				find_node = node
			}
		})
		return find_node
	}

	serialize() {
		return {
			'title': this.title,
			'childs'  : this.childs.map( node => node.serialize(node) )
		}
	}
}

class Tree   {

	constructor(data) {
		this.root = new TreeNode(data)
		this.initNode(this.root, data.children)
		return this.root
	}

	initNode(node, data) {
		for (let i = 0, len = data.length; i < len; i++) {
			var _data = data[i]

			var child = new TreeNode(_data)
			if (_data.children && _data.children.length > 0) {
				this.initNode(child, _data.children)
			}
			node.addChildren(child)
		}
	}
}

exports.Tree = Tree
exports.TreeNode = TreeNode