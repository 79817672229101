var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { title: "Поделиться" },
      on: { show: _vm.resetModal, hidden: _vm.resetModal, ok: _vm.handleOk }
    },
    [
      _c(
        "form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.handleSubmit($event)
            }
          }
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                state: _vm.state,
                label: "Укажите email пользователя",
                "label-for": "email-input",
                "invalid-feedback": "Укажите email пользователя"
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "email-input",
                  type: "email",
                  state: _vm.state,
                  required: ""
                },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }