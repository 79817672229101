<template >
	<div class='search p-2 bg-dark ' >
		<div class='form-group'>
			<div class="input-group">
				<div class="input-group-prepend">
					<div class="input-group-text"><v-icon icon='search' class='s20'></v-icon></div>
				</div>
				<input type="text" v-model='query' ref='search'
					v-on:keyup.stop.prevent='keyBindings'
					v-on:keyup.enter='doSearch'
				 class="form-control"  placeholder="строка запроса"></input>
			</div>
			<div v-if='found.length > 0'>
				<button class='btn' v-on:click="doPrev" v-if='found.length > 1'><v-icon icon='left' class='s20'></v-icon></button>
				<span v-if='found.length > 1'>{{current+1}} / {{found.length}}</span>
				<button class='btn'  v-on:click="doNext" v-if='found.length > 1'><v-icon icon='right' class='s20'></v-icon></button>
			</div>
		</div>
	</div>
</template>
<script>
const  TIME_DELAY = 1000
export default {
	name: 'search-form',
	data() {
		return {
			query: '',
			current: null,
			found: [],
			loading: false,
		}
	},
	created() {
		this.$nextTick(() =>this.$refs.search.focus())
	},

	destroyed() {
	},

	methods: {

		doNext() {this.$root.$emit('search-next')},
		doPrev() {this.$root.$emit('search-prev')},

		doSearch () {
			this.$emit('search-run',this.query)
		},

		keyBindings (event){
			if (event.which == 27 ) {
				this.$emit('search')
			}
		}
	}
}
</script>
<style scoped>
	.input {
		width:50em;
	}
	.search {
		position:absolute;
		bottom:0;
		left:0;
		width:30%;
	}
	.search .form-group {

		margin-bottom: 0;
	}
</style>