"use strict";

import axios from "axios";

const Konva = require('konva')
const _  = require ('lodash')
//const Color  = require ('color')
import Node from '../common/node.js'

const Compound   = require ('./compound.js')
const InfoBubble  = require('./info.js')

import STATE_STYLESHEETS  from  './theme.json'


//import fs from 'fs'
//let rawData = fs.readFileSync(__dirname + '/icons9.png')
//let base64Data = btoa(String.fromCharCode.apply(null, rawData));
const infoIcon  =  new Image ()
//infoIcon.src = 'data:image/png;base64,' + base64Data;
infoIcon.src = '';

const PROXY_FUNCTION  = [
    'x',
    'y',
    'position',
    'visible',
    'show',
    'hide',
    'remove',
    'move',
    'isVisible',
    'size',
    'moveToTop'
]
const STATE_PARAM  = {
	expand : false,
 	compose: false,
	color  : false,
	info   : false,
}

//module.exports = class SimpleTreeNode extends Node  {
export default class SimpleTreeNode extends Node  {

	/**
	 * После добавления к дереву собираем виджет
	 */
	constructor(data) {

		super(data.title)


		let view = this.view =  new Konva.Group({visible:false})
		view.setAttrs( data.view || {} 	)

		this._setProxyMethod()

		this.state = data.state || Object.assign({},STATE_PARAM)

		this.id    = data.id || '_' + Math.random().toString(36).substr(2, 9)

		this.styles = ['default']
		
		let label = new Konva.Group({id:'label'}).add(
				 new Konva.Rect({listening:false,id:'stroke', name:'cached'}),
				 new Konva.Rect({listening:false,id:'substrate', name:'cached'}),
				 new Konva.Text({text:this.title, id:'text'})
			)

		view.add(label)


		view.on('dragstart', evt => view.getLayer().fire('nodeDragstart',[this, evt]) )
		view.on('dragend',   evt => view.getLayer().fire('nodeDragend',  [this, evt])  )
		view.on('dragmove',  evt => view.getLayer().fire('nodeDragmove', [this, evt]) )
		view.on('mouseover', evt => (evt.target instanceof Konva.Text) &&  view.getLayer().fire('nodeMouseover',[this, evt]) )
		view.on('mouseout',  evt => view.getLayer().fire('nodeMouseout', [this, evt]) )



		view.on('visibleChange', evt => {
			this.compound && this.compound.visible(evt.newVal)
		})


		label.on('click', evt => {
			let event =  evt.evt.ctrlKey ?  'nodeSelect' : 'nodeClick';
			evt.cancelBubble = true
			view.getLayer().fire(event, [this, evt])

		})

	}

	forAllVisible(callback) {
		return this.forAllChilden(callback, false, (node) => node.isVisible() )
	}

	rect() {
		return {
			x: this.x(),
			y: this.y(),
			width: this.width(),
			height: this.height()
		}
	}
	getXCenter() {
		return  this.width() / 2 +  this.position().x
	}

	getYCenter() {
		return  this.height() / 2 +  this.position().y
	}

	signParentPositionX() {
		return this.isRoot() ? 1: Math.sign(this.parent.getXCenter() - this.getXCenter());
	}

	width() {
		return this.view.findOne('#label').getClientRect({skipStroke:true,skipShadow:true, relativeTo: this.view.getStage()}).width
	}

	height() {
		return this.view.findOne('#label').getClientRect({skipStroke:true,skipShadow:true, relativeTo: this.view.getStage()}).height
	}


	addChild(node) {
		super.addChild(node)
		this.addStyle('parent' ,false)
	}

	removeChild(node) {
		super.removeChild(node)
		if (this.isLast())  this.removeStyle('parent')
	}

	addStyle(style, redraw = true) {
		if (! this.styles.includes(style)) {
			this.styles.push(style)
			redraw && this.redraw()
		}
		return this
	}

	removeStyle(style , redraw = true){

		if (this.styles.includes(style)) {
			_.pull(this.styles,style)
			redraw &&  this.redraw()
		}

		return this
	}


	setStyles() {

		let calculate_style = {}


		this.styles.forEach((style) => {
			 calculate_style = _.defaultsDeep({}, STATE_STYLESHEETS[style],calculate_style)
		} )

		_.forEach (calculate_style, (attrs, id) =>  this.view.find(id).setAttrs(attrs) )
		return this
	}

	redraw( force = false) {

		if ((! this.view.isVisible() || ! this.inViewPort())  && ! force ) return

		this.setStyles()
		//let scale =  Math.max(view.getStage().scaleX(),1)
		this.view.find('.cached').cache()

		return this
	}




	_setProxyMethod() {
		// проксируем методы Konva.Group
		PROXY_FUNCTION.forEach( (method) => {
            this[method] = (...args) => this.view[method].apply(this.view, args)
        } )
        // проксируем методы состояний

	}


	initSize() {
		let label = this.view.findOne('#label'),
			stage = this.view.getStage(),
			stroke = this.view.findOne('#stroke'),
			substrate = this.view.findOne('#substrate')

		substrate.size({
				width:  label.getClientRect({ skipStroke:true,skipShadow:true, relativeTo: stage}).width,
				height: label.getClientRect({ skipStroke:true,skipShadow:true, relativeTo: stage}).height
			})

		stroke.setAttrs({
			offetX: -stroke.strokeWidth() ,
			offetY: -stroke.strokeWidth()  ,
			width:  label.getClientRect({ skipStroke:true,skipShadow:true, relativeTo: stage}).width   ,
			height: label.getClientRect({ skipStroke:true,skipShadow:true, relativeTo: stage}).height
		})
		return this
	}


	setColor (color) {
		this.state.color = color
	}

	initCompound(clear = false) {

		if (! this.isRoot()) {

			if (! this.compound) {
				this.compound =  new Compound(this)
				this.view.parent.add (this.compound) // добавим на слой
				this.compound.zIndex(0)
				this.compound.visible(this.view.isVisible())
			}
			if ( ! this.state.color ||  clear ) {
				let color = this.parent.isRoot() ?  Konva.Util.getRandomColor() : this.parent.state.color
				this.setColor(color)
			}

			this.compound.setAttrs({stroke:this.state.color})

		}

		return this

	}

	setTitle (title)  {
		super.setTitle(title)
		this.view.findOne('#text').text(title)
		this.initSize().redraw()
		this.view.getLayer().batchDraw()

	}




	toggleInfo(evt) {

		if ( this.state.info )  {
			this.destroyInfo()
		} else {
			this.showInfo()
		}
		evt.cancelBubble = true
	}

	getInfo() {
		let info  = this.view.findOne('#info')
	}

	showInfo(sel = false) {
		if ( ! this.view.findOne('#info')  )  {
		axios.get( `/seo/node/data/${this.id}`)
			.then(response => {
				let info = new InfoBubble(response.data.data, sel ).id('info')
				info.position({x: 0, y: -info.height()})
				info.on('click', evt => this.destroyInfo()  )
				this.view.add(info)
				this.view.moveToTop()
				this.view.getLayer().batchDraw()
				this.state.info = true
			})
		}
	}



	destroyInfo() {
		if (  this.view.findOne('#info') )  {
			this.view.findOne('#info').destroy()
			this.state.info = false
		}
		this.view.getLayer().batchDraw()
		return this
	}



	init() {


		let view = this.view

		if (this.initialized )  {
			console.warn('Node already initialized')
			return
		}

		this.view.draggable( ! this.isRoot() )


		if ( this.isRoot() )  {
			this.addStyle('root', false)
		}

		if ( this.hasQueries() )  {

			//let infoButton = new Konva.Image( {image:infoIcon,  x:2 , y:5, width:15, height:15 , id:'infoButton' } )
			let infoButton = new Konva.Text({
				x: 5,
				y: 4,
				text: '+',
				fontSize: 14,
				fontFamily: 'Calibri',
				fill: 'green',
			});
			infoButton.on('click', evt => this.toggleInfo(evt) )
			view.findOne('#text').x(18)
			view.findOne('#label').add(infoButton)

			if ( this.state.info) {
				this.showInfo()
			}

		}

		
		//console.log(this.getPositionX() + ',' + this.getPositionY())
		//this.position({x:this.getPositionX(), y: this.getPositionY()})
		
		

		this.setStyles().initCompound().initSize()
		this.initialized = true
		return this

	}

	/**
	 * [inViewPort description]
	 * @param  {Boolean} full [description]
	 * @return {[type]}       [description]
	 */
	inViewPort( full = false   ) {
		if ( ! this.view.getStage() )  return true
		let position = this.view.absolutePosition()
		let viewPort = this.view.getStage().size()
		let width  = this.width()
		let height = this.height()
		let result =   position.x > (full ? 0 : -width)  &&
					   position.x < (full ? viewPort.width - width : viewPort.width)  &&
					   position.y > (full ? 0 : -height)  &&
				 	   position.y < (full ? viewPort.height - height : viewPort.height)

 	   	return result
	}


	serialize() {
		let data =  {
			'title'   : this.title,
			'id'   	  : this.id,
			'queries' : this.queries.map( (q) =>  q.serialize() ) ,
			'childs'  : this.childs.map( node => node.serialize() ),
			'state'   : this.state,
			'view' 	  : this.view.getAttrs()
		}


		return data;
	}



}






1