"use strict"; 

/**
 * Данные запросов 
 * @type {[type]}
 */
export default class Query    {
     constructor(data)  {
        //  заголовок 
        this.title = data.title
        // частота 
        this.param = data.param
     }

     serialize() {
        return {
            'title':this.title,
            'param' :this.param,
        }
     }
}