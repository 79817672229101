<template>
	<b-navbar-nav >
		<b-button-group>
			<button v-on:click="$root.$emit('dashboard.reload')"  title='Перегрузить' class='btn btn-sm btn-outline-dark'>
				<v-icon icon='synchronize' />
			</button>
		</b-button-group>
	</b-navbar-nav>
</template>

<script>
export default { name: 'v-nav-tool'}
</script>