"use strict";
const _  = require ('lodash')

export default class Node    {

	constructor(title) {
		this.title 	= title
		this.parent = null
		this.id = null
		this.hasdata = null
		this.childs	= []
		this.queries= []
		this.expanded = false
		this.arr_pos = []
		
	}
	setId(id) {
		this.id = id
	}
	hasData(value) {
		this.hasdata = value
	}
	isLast() {
		return  this.childs.length == 0
	}

	hasQueries() {
		return  this.hasdata
	}

	setTitle (title)  {
		this.title = title
	}
	
	setExpanded(value)
	{
		this.expanded = value
	}
	
	setPosition(str_position)
	{
		this.arr_pos = str_position.split(',')
	}

	getPositionX() { 
	
		return this.arr_pos[0]!="" ? parseFloat(this.arr_pos[0]) : 0; 
	}
	
	getPositionY() { 
	
		return this.arr_pos[1]!="" ? parseFloat(this.arr_pos[1]) : 0; 
	}

	/**
	 * [add description]
	 * @param Node child [description]
	 */
	addChild( node ) {
		this.childs.push( node )
		node.parent && node.parent.removeChild(node)
		node.addParent(this)
		return this
	}

	addParent( node ) {
		this.parent = node
		return this
	}

	removeChild(node) {
		_.pull(this.childs,node)
		return this
	}



	addQuerie( query) {
		this.queries.push(query)
		return this;
	}

	isRoot()
	{
		return ! this.parent
	}


	static _forAllChilden(node,callback, filter, level = 1 ) {
		node.childs.filter(filter).forEach(
			(node) => {
				callback.apply( node, [node , level] )
				this._forAllChilden(node,callback,filter, level+1 )
			})
	}

	/**
	 * Выполнить для всех потомков
	 * @param  Node   node    корнева ветка
	 * @param  {Function} callback [description]
	 * @param  {Function}  filter   [description]
	 * @return null
	 */
	forAllChilden(callback, self = false, filter = () => true) {
		self &&  callback.apply(this, [this, 0 ] )
		this.constructor._forAllChilden(this,callback, filter)
	}


	/**
	 * Вернуть всех потомков
	 */
	allCildren(self, filter = () => true) {
		let childs = []
		this.forAllChilden(
			(node) =>  childs.push(node),
			self,
			filter
		)
		return childs
	}

	/**
	 * Проверить является ли узел потомком
	 * @param  Node  node проверяемый предок
	 * @return {Boolean}      [description]
	 */
	isChild(node) {
		return this.allParents().includes(node)
	}

	/**
	 * Вернуть всех родителей
	 * @param  Node   node    корнева ветка
	 * @param  {Function} callback [description]
	 * @param  {Function}  filter   [description]
	 * @return null
	 */

	allParents(self = false) {
		let parents = self ? [this] : []
		let current  = this

		while ( ! current.isRoot() ) {
			current = current.parent
			parents.push(current)
		}

		return parents
	}

	/**
	 * Частота запросов в текущем кластере баз дочерних
	 * @return {[type]} [description]
	 */
	countFreq () {
		return  this.queries.reduce((acc, item) => acc + item.freq,0  )
	}

	countTotalFreq () {
		let freq = this.countFreq ();
		this.forAllChilden( this, () => freq += item.countFreq() )
		return freq
	}

	forAllView(callback, self = true, filter = () => true) {

        this.forAllChilden(
            (node) => callback.apply(node.view, [this] ) ,
            self,
            filter
        )
    }


	serialize() {
		return {
			'title': this.title,
			'queries' : this.queries.map( (q) => [ q.serialize() ] ) ,
			'childs'  : this.childs.map( node => node.serialize(node) )
		}
	}


}
